import React from "react";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";
const { Text } = Typography;

const PurchaseSupplierOrderStatus = ({ status }) => {
  const { t } = useTranslation();
  const getStatusText = (status) => {
    switch (status) {
      case 'NEW':
        return t('status.purchaseSupplierOrderStatus.NEW');
      case 'PENDING_PAY':
        return t('status.purchaseSupplierOrderStatus.PENDING_PAY');
      case 'PARTIAL_PAID':
        return t('status.purchaseSupplierOrderStatus.PARTIAL_PAID');
      case 'PAID':
        return t('status.purchaseSupplierOrderStatus.PAID');
      case 'COMPLETED':
        return t('status.purchaseSupplierOrderStatus.COMPLETED');
      case 'CANCELLED':
        return t('status.purchaseSupplierOrderStatus.CANCELLED');
      case 'SHIPPED':
        return t('status.purchaseSupplierOrderStatus.SHIPPED');
      case 'PARTIAL_RECEIVED':
        return t('status.purchaseSupplierOrderStatus.PARTIAL_RECEIVED');
      case 'RECEIVED':
        return t('status.purchaseSupplierOrderStatus.RECEIVED');
      case 'IN_WAREHOUSE':
        return t('status.purchaseSupplierOrderStatus.IN_WAREHOUSE');
      case 'IN_TRANSIT':
        return t('status.purchaseSupplierOrderStatus.IN_TRANSIT');
      case 'REFUNDED':
        return t('status.purchaseSupplierOrderStatus.REFUNDED');
      case 'RETURNED':
        return t('status.purchaseSupplierOrderStatus.RETURNED');
      case 'FAILED':
        return t('status.purchaseSupplierOrderStatus.FAILED');
      default:
        return t('status.purchaseSupplierOrderStatus.UNKNOWN');
    }
  };

  return <Text>{getStatusText(status)}</Text>;
};


export default PurchaseSupplierOrderStatus;