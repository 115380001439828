import React, { useState, useEffect } from 'react';
import { Descriptions, Card, Button, Table, Popover, Tag, Tabs, Typography, Timeline, Row, Col, DatePicker, Select, Input, Pagination } from 'antd';
import { FileTextOutlined, ClockCircleOutlined, UserOutlined, FormOutlined, DownOutlined, UpOutlined, ShopOutlined, ProductOutlined, CheckCircleOutlined} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PurchaseOrderStatus from './component/status/PurchaseOrderStatus';
import './OrderFullfillList.less';
import purchaseOrderApi from '../servapi/purchase_order';
import saleOrderApi from '../servapi/sale_order';
import SaleOrderStatus from './component/status/SaleOrderStatus';
import PurchaseSupplierOrderStatus from './component/status/PurchaseSupplierOrderStatus';
import PurchaseSupplierOrderPayStatus from './component/status/PurchaseSupplierOrderPayStatus';
import SupplierCrossOrderProductLogisticsStatus from './component/status/SupplierCrossOrderProductLogisticsStatus';
import LogisticsTrackingPopup from './component/LogisticsTraceOverlay';
import { constructAddress } from '../util/addr';
import { formatDateTime } from '../util/date';
import shopApi from '../servapi/shop';
import moment from 'moment';
import { preventAndStop } from '../util/common';
import PurchaseSupplierOrderProductStatus from './component/status/PurchaseSupplierOrderProductStatus';
const { Option } = Select;
const { RangePicker } = DatePicker;
const { Text } = Typography;
const { TabPane } = Tabs;


const LogisticsTracking = ({ id }) => {
  const [data, setData] = useState();

  useEffect(() => {
    if (data) {
      return;
    }
    purchaseOrderApi.getPurchaseOrderProductLogisticsTraces(id)
      .then(resp => {
        if (resp && resp.length > 0) {
          setData(resp[0])
        }
      })
      .catch(console.log);
  }, [])

  const sortedLogisticsSteps = data?.logisticsSteps?.sort((a, b) => new Date(b.acceptTime) - new Date(a.acceptTime));

  return (
    <div style={{ maxWidth: '300px', maxHeight: '400px', overflowY: 'auto', width: 300 }}>
      {/* 物流信息详情 */}
      <div style={{ marginBottom: '16px' }}>
        <Text strong>物流编号: </Text><Text>{data?.logisticsId}</Text><br />
        <Text strong>物流单编号: </Text><Text>{data?.logisticsBillNo}</Text><br />
        <Text strong>订单编号: </Text><Text>{data?.crossOrderId}</Text>
      </div>

      {/* 物流追踪时间线 */}
      <Timeline>
        {sortedLogisticsSteps?.map((step, index) => (
          <Timeline.Item key={index}>
            <div>{step.acceptTime}</div>
            <div>{step.remark}</div>
          </Timeline.Item>
        ))}
      </Timeline>
    </div>
  )
}

const IntlLogisticsTracking = ({ id }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (data.length > 0) {
      return;
    }

    purchaseOrderApi.getPurchaseOrderProductIntlLogisticsTraces(id)
      .then(resp => {
        if (resp && resp.length > 0) {
          setData(resp)
        }
      })
      .catch(console.log);
  }, []);

  const sortedLogisticsSteps = data.sort((a, b) => new Date(b.dates) - new Date(a.dates));
  return (
    <div style={{ maxWidth: '500px', maxHeight: '600px', overflowY: 'auto', width: 300  }}>

      {/* 物流追踪时间线 */}
      <Timeline style={{ paddingTop: 8}}>
        {sortedLogisticsSteps?.map((step, index) => (
          <Timeline.Item key={index}>
            <div>{step.dates}</div>
            <div>{step.content}</div>
          </Timeline.Item>
        ))}
      </Timeline>
    </div>
  )
}


const LogisticsQueryPopover = ({ record = {} }) => {
  const { t } = useTranslation();
  const [popoverVisible, setPopoverVisible] = useState(false); // State for Popover visibility
  const [selectedTab, setSelectedTab] = useState('logistics'); // State for selected tab

  const handlePopoverVisibleChange = (visible) => {
    setPopoverVisible(visible);
  };

  const handleTabChange = (key) => {
    setSelectedTab(key);
  };

  return (
    <Popover
      content={
        <Tabs defaultActiveKey={selectedTab} onChange={handleTabChange}>
          <TabPane tab={t("shopOrders.orderDetail.tab.logistics")} key="logistics">
            <LogisticsTracking id={record.id} />
          </TabPane>
          <TabPane tab={t("shopOrders.orderDetail.tab.intl_logistics")} key="intl_logistics">
            <IntlLogisticsTracking id={record.id} />
          </TabPane>
        </Tabs>
      }
      title={t("shopOrders.orderDetail.logisticsQuery")}
      visible={popoverVisible}
      onVisibleChange={handlePopoverVisibleChange}
      trigger={"click"}
    >
      <Text ellipsis={{ rows: 3, expandable: false }} className="ant-typography-secondary"><a>{t("shopOrders.orderDetail.logisticsQuery")}</a></Text>
    </Popover>
  )
}


const SupplierOrderCard = ({ supplierOrder }) => {
  const { t } = useTranslation();
  const [showAll, setShowAll] = useState(false);

  const getOrderProductLogisticsStatus = record => {
    return record.status;

    // const { crossOrders = [] } = supplierOrder;
    // const crossOrderProducts = crossOrders.map(crossOrder => crossOrder.products).reduce((sum, products) => [...sum, ...products], []);

    // const filtered = crossOrderProducts.filter(crossOrderProduct => `${crossOrderProduct.skuId}` === record.sku);

    // if (filtered.length > 0) {
    //   return filtered[0].logisticsStatus;
    // }
  }

  const columns = [
    {
      title: 'Product',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={record.productImage} alt={record.productName} style={{ height: 56, width: 56, border: '1px solid #eee', marginRight: 8 }} />
          <span>{record.productName}</span>
        </div>
      ),
      width: '30%'
    },
    {
      title: 'Sku',
      dataIndex: 'skuInfo',
      key: 'skuInfo',
      width: '15%'
    },
    {
      title: 'Price',
      dataIndex: 'unitPrice',
      key: 'unitPrice',
      width: '10%',
      render: (_, record) => (<span>{t("common.currency.symbol")}{record.unitPrice}</span>)
    },
    {
      title: 'Quantity',
      key: 'quantity',
      render: (text, record) => (
        <>
          <div><strong>{record.quantity}</strong></div>
        </>
      ),
      width: '10%'
    },
    {
      title: 'Status',
      key: 'Status',
      render: (text, record) => (
        <>
          <div><Tag><PurchaseSupplierOrderProductStatus status={getOrderProductLogisticsStatus(record)} /></Tag></div>
        </>
      ),
      width: '15%'
    },
    {
      title: 'Detail',
      key: 'detail',
      render: (text, record) => (
        <>
         <div style={{ color: '#999', cursor: 'pointer' }}>
            <LogisticsQueryPopover record={record}  />
          </div>
        </>
      ),
      width: '20%'
    },
  ];
  const dataSource = showAll ? supplierOrder.orderProducts : supplierOrder.orderProducts.slice(0, 3);
  return (
    <Card bodyStyle={{ padding: 0, }} style={{ borderRadius: 0 }} className='OVWKvl3A'>
      <Card bodyStyle={{ paddingTop: 8, paddingBottom: 8 }} style={{ border: 0 }}>
        <Card.Meta
          title={
            <div style={{ display: 'flex', alignItems: 'center', fontSize: 14 }}>
              <div style={{ display: 'flex', alignItems: 'center', fontSize: 14, paddingLeft: 8 }}>
                <span>供应商采购单：</span>
                <span style={{ marginLeft: 8 }}>{supplierOrder.orderNo}</span>
              </div>
            </div>
          }
          description={
            <Descriptions size="small" column={3} style={{ paddingLeft: 8 }}>
              {/* <Descriptions.Item>1688订单：<a href="/cn/admin/orders/po/P3048557127001">P3048557127001</a></Descriptions.Item> */}
              <Descriptions.Item>供应商：
                <a href={`https://s.1688.com/company/company_search.htm?keywords=${supplierOrder.supplier.companyName}`} target='_blank'>
                  <span style={{ marginLeft: 8 }}>{supplierOrder.supplier.companyName ? supplierOrder.supplier.companyName : supplierOrder.supplier.supplierNo}</span>
                </a>
              </Descriptions.Item>
              <Descriptions.Item>订单状态：<Tag><PurchaseSupplierOrderStatus status={supplierOrder.status} /></Tag></Descriptions.Item>
              <Descriptions.Item>支付状态：<Tag><PurchaseSupplierOrderPayStatus status={supplierOrder.payStatus} /></Tag></Descriptions.Item>
              {/* <Descriptions.Item>物流状态：<Tag>查询物流</Tag></Descriptions.Item> */}
            </Descriptions>
          }
        />
      </Card>
      <div>
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          size="small"
          style={{ marginBottom: 16, borderRadius: 0 }}
        />
        {
          supplierOrder.orderProducts.length > 3 && (
            <div style={{ textAlign: 'center', }}>
              <Button type="link" onClick={() => setShowAll(!showAll)}>
                {showAll ? <UpOutlined /> : <DownOutlined />}
                {showAll ? '隐藏' : `还有${supplierOrder.orderProducts.length - 3}个商品，查看所有`}
              </Button>
            </div>
          )
        }
      </div>
    </Card>
  );
};

const PurchaseOrderCard = ({ purchaseOrder = {}, onConfirm }) => {

  const text = '11';
  return (
    <Card bodyStyle={{ padding: 0, background: 'transparent', }} style={{ marginBottom: 16, borderRadius: 0, border: 0 }}>
      <table className='iIzGMbY1' style={{ marginBottom: 8, paddingTop: 8, background: '#eff8ff' }} >
        <tbody>
          <tr>
            <td>
              <Descriptions style={{ paddingLeft: 16, paddingTop: 8 }}>
                <Descriptions.Item>
                  <FileTextOutlined /> <span className="ml-8">采购订单：</span>
                  <a target="_blank" href={`/cn/admin/orders/so/${text}`}>{purchaseOrder.orderNo}</a>
                </Descriptions.Item>
              </Descriptions></td>
            <td>
              <Descriptions style={{ paddingTop: 8 }}>
                <Descriptions.Item>
                  <FileTextOutlined /> <span className="ml-8">采购状态：</span>
                  <a target="_blank" href={`/cn/admin/orders/so/${text}`}><PurchaseOrderStatus status={purchaseOrder.status} /></a>
                </Descriptions.Item>
              </Descriptions></td>
            <td>
              <Descriptions style={{ paddingTop: 8 }}>
                <Descriptions.Item>
                  <ClockCircleOutlined /> <span className="ml-8">创建时间：</span>
                  <span>{formatDateTime(purchaseOrder.createdTime)}</span>
                </Descriptions.Item>
              </Descriptions></td>
          </tr>
          <tr>
            <td>
              <Descriptions style={{ paddingLeft: 16, paddingTop: 8 }}>
                <Descriptions.Item>
                  <UserOutlined /> <span className="ml-8">物流状态：</span>
                  <span>物流查询</span>
                </Descriptions.Item>
              </Descriptions></td>

            <td>
              <div>
                {
                  purchaseOrder.status !== 'NEW' && (
                    <Descriptions style={{ paddingTop: 8 }}>
                      <Descriptions.Item>
                        <UserOutlined /> <span className="ml-8">已支付订单数：</span>
                        <span>{purchaseOrder.purchaseSupplierOrders.filter(purchaseSupplierOrder => purchaseSupplierOrder.payStatus === 'PAID').length}/{purchaseOrder.purchaseSupplierOrders.length}</span>
                      </Descriptions.Item>
                    </Descriptions>
                  )
                }
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      {
        (purchaseOrder.purchaseSupplierOrders || []).map(supplierOrder => <SupplierOrderCard supplierOrder={supplierOrder} />)
      }

    </Card>
  )
}

const SearchOrders = ({ onSync, onSearch, onReset }) => {
  // 定义每个查询条件的状态
      const { t } = useTranslation();
  const [shopId, setShopId] = useState(null);
  const [orderOrProductName, setOrderOrProductName] = useState('');
  const [orderSource, setOrderSource] = useState(null);
  const [recipientName, setRecipientName] = useState('');
  const [status, setStatus] = useState(null);
  const [dateRange, setDateRange] = useState([]);
  const [shops, setShops] = useState([]);

  useEffect(() => {
    shopApi.getShopList()
      .then(setShops)
      .catch(console.log);
  }, []);

  
  // 重置表单
  const resetFilters = () => {
    setShopId(null);
    setOrderOrProductName('');
    setOrderSource(null);
    setRecipientName('');
    setStatus(null);
    setDateRange([]);

    onReset();
  };

  // 处理搜索逻辑
  const handleSearch = () => {
    const searchCriteria = {
      shopId,
      orderOrProductName,
      orderSource,
      recipientName,
      status,
      startDate: dateRange && dateRange[0] ? dateRange[0].format('YYYY-MM-DD') + ' 00:00:00' : null,
      endDate: dateRange && dateRange[1] ? dateRange[1].format('YYYY-MM-DD') + ' 23:59:59' : null,
    };
    onSearch(searchCriteria); // 将查询条件传递给父组件或 API 调用
  };

    const ranges = {
      [t('shopOrders.search.timeRanges.recent3day')]: [moment().subtract(3, 'days'), moment()],
      [t('shopOrders.search.timeRanges.recentWeek')]: [moment().subtract(7, 'days'), moment()],
      [t('shopOrders.search.timeRanges.recentMonth')]: [moment().subtract(1, 'months'), moment()],
    };
    
    const statuses = {
      "NEW": t("status.saleOrderStatus.NEW"),
      "PENDING_CONFIRM": t("status.saleOrderStatus.PENDING_CONFIRM"),
      "CONFIRMED": t("status.saleOrderStatus.CONFIRMED"),
      "PROCESSING": t("status.saleOrderStatus.PROCESSING"),
      "COMPLETED": t("status.saleOrderStatus.COMPLETED"),
      "CANCELLED": t("status.saleOrderStatus.CANCELLED"),
      "ON_HOLD": t("status.saleOrderStatus.ON_HOLD"),
      "REFUNDED": t("status.saleOrderStatus.REFUNDED"),
      "RETURNED": t("status.saleOrderStatus.RETURNED"),
      "FAILED": t("status.saleOrderStatus.FAILED"),
    }
  return (
    <Card title="搜索订单" style={{ margin: '20px' }}>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div style={{ marginRight: '16px', marginBottom: '8px' }}>
          <div style={{ marginBottom: '6px', color: 'rgba(0, 0, 0, 0.85)' }}>店铺</div>
          <Select 
            value={shopId} 
            onChange={setShopId} 
            placeholder="全部" 
            style={{ width: '300px' }} 
            allowClear
          >
            {
              shops.map(shop => ( <Option value={shop.id}>{shop.shopName} - {shop.shopUrl}</Option>))
            }
          </Select>
        </div>

        <div style={{ marginRight: '16px', marginBottom: '8px' }}>
          <div style={{ marginBottom: '6px', color: 'rgba(0, 0, 0, 0.85)' }}>订单号或商品名称</div>
          <Input 
            value={orderOrProductName}
            onChange={e => setOrderOrProductName(e.target.value)} 
            allowClear
            placeholder="订单号或商品名称" 
            style={{ width: '260px' }} 
          />
        </div>

        <div style={{ marginRight: '16px', marginBottom: '8px' }}>
          <div style={{ marginBottom: '6px', color: 'rgba(0, 0, 0, 0.85)' }}>收件人姓名</div>
          <Input 
            value={recipientName}
            onChange={e => setRecipientName(e.target.value)} 
            allowClear
            placeholder="输入收件人姓名" 
            style={{ width: '260px' }} 
          />
        </div>

        <div style={{ marginRight: '16px', marginBottom: '8px' }}>
          <div style={{ marginBottom: '6px', color: 'rgba(0, 0, 0, 0.85)' }}>订单状态</div>
          <Select
            value={status}
            onChange={setStatus}
            placeholder={t("shopOrders.search.all")}
            style={{ width: '180px' }}
            allowClear
          >
            {
              Object.keys(statuses).map(key => <Option value={key}>{statuses[key]}</Option>)
            }
          </Select>
        </div>

        <div style={{ marginRight: '16px', marginBottom: '8px' }}>
          <div style={{ marginBottom: '6px', color: 'rgba(0, 0, 0, 0.85)' }}>时间区间</div>
          <RangePicker 
            value={dateRange}
            onChange={setDateRange}
            ranges={ranges}
            style={{ width: '256px' }} 
          />
        </div>

        <div style={{ marginRight: '16px', marginBottom: '8px', marginTop: 24,  display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
          <Button type="primary" style={{ marginRight: 8 }} onClick={handleSearch}>搜索</Button>
          <Button style={{ marginRight: 8 }} onClick={resetFilters}>清除</Button>
          {/* <Button type="primary" style={{ marginRight: 8 }} onClick={onSync}>同步订单</Button> */}
        </div>
      </div>
    </Card>
  );
};


const OrderFullfillCard = ({ saleOrderFulfillment }) => {
  const [showPurchaseOrders, setShowPurchaseOrders] = useState(false);

  const [purchaseOrder, setPurchaseOrder] = useState({});

  useEffect(() => {
    purchaseOrderApi.getPurchaseOrder(20)
      .then(setPurchaseOrder)
  }, [])

  const text = '111';

  const togglePurchaseOrders = () => {
    setShowPurchaseOrders(prevState => !prevState);
  };

  const getCustomerAddress = () => {
    return `${saleOrderFulfillment.deliveryFirstName},${saleOrderFulfillment.deliveryStreetAddress},${saleOrderFulfillment.deliveryCity},${saleOrderFulfillment.deliveryState}`
  }

  const customerInfoContent = (
    <div>
      <div><strong>姓名：</strong>{saleOrderFulfillment.deliveryFirstName}</div>
      <div><strong>地址：</strong>{getCustomerAddress()}</div>
    </div>
  );

  return (
    <Card bodyStyle={{ padding: 0, background: 'transparent' }} style={{ marginBottom: 16 }}>
      <table className='iIzGMbY1' style={{ marginBottom: 8, paddingTop: 8, cursor: 'pointer' }} onClick={togglePurchaseOrders} >
        <tbody>
          <tr>
            <td>
              <Descriptions style={{ paddingLeft: 16, paddingTop: 8 }}>
                <Descriptions.Item>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FileTextOutlined /> <span className="ml-8">销售订单：</span>
                    <a target="_blank" href={`/cn/admin/orders/so/${text}`}>{saleOrderFulfillment.orderNo}</a>
                  </div>

                </Descriptions.Item>
              </Descriptions></td>
            <td>
              <Descriptions style={{ paddingTop: 8 }}>
                <Descriptions.Item>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <CheckCircleOutlined /> <span className="ml-8">订单状态：</span>
                    <a target="_blank" href={`/cn/admin/orders/so/${text}`}>{<SaleOrderStatus status={saleOrderFulfillment.orderStatus} />}</a>
                  </div>

                </Descriptions.Item>
              </Descriptions></td>
            <td>
              <Descriptions style={{ paddingTop: 8 }}>
                <Descriptions.Item>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ClockCircleOutlined /> <span className="ml-8">创建时间：</span>
                    <span>{formatDateTime(saleOrderFulfillment.createdTime)}</span>
                  </div>

                </Descriptions.Item>
              </Descriptions></td>
          </tr>
          <tr>
            <td>
              <Descriptions style={{ paddingLeft: 16 }}>
                <Descriptions.Item>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <UserOutlined /> <span className="ml-8">客户信息：</span>
                    <Popover content={customerInfoContent} title="客户信息" trigger="click" onClick={e => preventAndStop(e)}>
                      <span>{saleOrderFulfillment.deliveryFirstName}</span>
                    </Popover>
                  </div>
                </Descriptions.Item>
              </Descriptions>
            </td>
            <td>
              <Descriptions>
                <Descriptions.Item>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ProductOutlined /> <span className="ml-8">已采商品数：</span>
                    <span>{saleOrderFulfillment.orderProducts.filter(orderProduct => orderProduct.psoOrderId > 0).length}/{saleOrderFulfillment.orderProducts.length}</span>
                  </div>
                </Descriptions.Item>
              </Descriptions>
            </td>
            <td>
              <Descriptions>
                <Descriptions.Item>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ShopOutlined /> <span className="ml-8">店铺：</span>
                    <span>{saleOrderFulfillment.shop.shopName}</span>
                  </div>
                </Descriptions.Item>
              </Descriptions>
            </td>
          </tr>
        </tbody>
        {
          saleOrderFulfillment.orderStatus === 'COMPLETED' && (
            <div style={{ position: 'absolute', top: 4, left: 4}}>
              <CheckCircleOutlined style={{ color: '#52c41a', fontSize: 18  }}/>
            </div>
          )
        }
      </table>
      {
        saleOrderFulfillment.purchaseOrders.length > 0 && (
          <div style={{ position: 'absolute', top: 0, right: 0 }}>
            <Button type="link" onClick={togglePurchaseOrders}>
              {showPurchaseOrders ? <UpOutlined /> : <DownOutlined />}订单详情
            </Button>
          </div>
        )
      }
      {
        showPurchaseOrders && (
          saleOrderFulfillment.purchaseOrders.map(purchaseOrder => <PurchaseOrderCard purchaseOrder={purchaseOrder} />)
        )
      }
    </Card>
  )
}


const OrderFullfilList = () => {
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [searchParams, setSearchParams] = useState({});

  useEffect(() => {
    fetchSaleOrderFulfillments({ searchParams, pagination })
  }, [pagination.current, pagination.size, searchParams])

  const handlePageChange = (current, pageSize) => {
    setPagination({
      ...pagination,
      current,
      pageSize,
    })
  };

  const fetchSaleOrderFulfillments = async ({ searchParams, pagination }) => {
    const pageData = await saleOrderApi.getSaleOrderFulfillments({
      ...searchParams,
      page: pagination.current - 1,
      size: pagination.pageSize,
    })

    setData(pageData.saleOrderFulfillments || []);
    setPagination({
      ...pagination,
      current: pageData.number + 1,
      total: pageData.recordsTotal,
    })
  }

  return (
    <>
      <div className="page_head">
        <div id="page_head_content" style={{ height: '72px' }}>
          <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
            <Col span={12}>
              <div className="flex-center-vertical">
                <h3 className="ant-typography ant-typography-ellipsis ant-typography-single-line ant-typography-ellipsis-single-line text-24" style={{ margin: 0, lineHeight: '72px', minHeight: '72px', padding: '0px 8px', flexGrow: 1 }}>
                  订单履约
                </h3>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className='inner-content relative px-[16px]' style={{ paddingTop: '82px', paddingBottom: '32px' }}>
        <SearchOrders onSearch={setSearchParams} onReset={() => setSearchParams({})} />
        <div className="order-detail-list" style={{ margin: 20 }}>
          {
            data.map(saleOrderFulfillment => <OrderFullfillCard saleOrderFulfillment={saleOrderFulfillment} />)
          }

          {/* <OrderFullfillCard /> */}
        </div>
        <div className="pagination" style={{ display: 'flex', justifyContent: 'flex-end', margin: 20 }} >
          <Pagination
            current={pagination.current}
            pageSize={pagination.pageSize}
            total={pagination.total}
            onChange={handlePageChange}
            showSizeChanger
            pageSizeOptions={['5', '10', '20']}
          />
        </div>
      </div>
    </>

  );
};

export default OrderFullfilList;
