import React, { useState, useEffect } from 'react';
import { Table, Card, Space, Typography, Tag, Button, Descriptions, Selec, Radio, Modal, InputNumber, Popconfirm, Select, Row, Col, Popover, Tooltip } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import purchaseOrderApi from '../servapi/purchase_order';
import logisticsApi from '../servapi/logistics';
import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { formatDateTime } from '../util/date';
import LogisticsTrackingPopup from './component/LogisticsTraceOverlay';
import CreateWarehouseReceiptOverlay from './component/CreateWarehouseReceiptOverlay';
import { saveAs } from "file-saver";
import { Link, useParams } from 'react-router-dom';
import IntlLogisticsTrackingPopup from './component/IntlLogisticsTraceOverlay';

const { Title, Text } = Typography;
const { Option } = Select;
const RadioGroup = Radio.Group;

function getDomesticsLogisticsStatus(status) {
  const statusMap = {
    'WAITACCEPT': '未受理',
    'CANCEL': '已撤销',
    'ACCEPT': '已受理',
    'TRANSPORT': '运输中',
    'NOGET': '揽件失败',
    'SIGN': '已签收',
    'UNSIGN': '签收异常'
  };

  return statusMap[status] || '未知状态'; // 如果状态不在映射中，返回 '未知状态'
}

// 1 未发货
// 2 已发货
// 3 已收货
// 4 已经退货
// 5 部分发货
// 8 还未创建物流订单
function getCrossOrderLogisticsStatus(logisticsStatus) {
  const statusMap = {
    '1': '未发货',
    '2': '已发货',
    '3': '已收货',
    '4': '已经退货',
    '5': '部分发货',
    '8': '待处理',
  };

  return statusMap[`${logisticsStatus}`] || '';
}

// 模拟数据
const procurementOrder = {
  orderNumber: 'PO-20240203',
  salesOrderNumber: 'SO-56789',
  createdAt: '2025-02-03 10:30:00',
  status: '待处理',
  suppliers: [
    {
      supplierOrderNumber: 'SP-001',
      logisticsStatus: '运输中',
      supplierName: '供应商A',
      contact: 'supplierA@example.com',
      crossBorderOrders: [
        {
          crossBorderOrderNumber: 'CB-1001',
          logisticsOrders: [
            {
              logisticsOrderNumber: 'LO-5001',
              logisticsStatus: '已发货',
              items: [
                { name: '商品A', quantity: 10, sku: 'SKU12345', unit: '件' },
                { name: '商品B', quantity: 5, sku: 'SKU67890', unit: '件' },
              ],
            },
            {
              logisticsOrderNumber: 'LO-5002',
              logisticsStatus: '待发货',
              items: [
                { name: '商品C', quantity: 8, sku: 'SKU54321', unit: '件' },
              ],
            },
          ],
        },
      ],
    },
    {
      supplierOrderNumber: 'SP-002',
      logisticsStatus: '待发货',
      supplierName: '供应商B',
      contact: 'supplierB@example.com',
      crossBorderOrders: [
        {
          crossBorderOrderNumber: 'CB-1002',
          logisticsOrders: [
            {
              logisticsOrderNumber: 'LO-5003',
              logisticsStatus: '运输中',
              items: [
                { name: '商品D', quantity: 15, sku: 'SKU99999', unit: '件' },
              ],
            },
          ],
        },
      ],
    },
  ],
};

const OrderProductTable = ({ orderProducts, warehouseReceipts = [], warehouseReceiptCategories = [], onReload }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [warehouseReceiptOverlay, setWarehouseReceiptOverlay] = useState(false);

  const handleDownloadReceiptItemPdf = async (warehouseReceiptItemId) => {
    await logisticsApi.downloadWarehouseReceiptItemPdf(warehouseReceiptItemId);
  }

  const columns = [
    {
      title: '商品',
      dataIndex: 'name',
      width: '18%',
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={record.productImage} alt={record.productName} style={{ height: 56, width: 56, border: '1px solid #eee', marginRight: 8 }} />
          <span
            style={{
              display: 'block',
              width: '200px', // 可根据实际宽度调整
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              lineHeight: '1.2em',
              maxHeight: '2.4em', // 2行文字的高度（1.2em * 2）
            }}
          >{record.productName}</span>
        </div>
      ),
    },
    {
      title: 'SKU',
      dataIndex: 'skuInfo',
      width: '12%',
    },
    {
      title: '数量',
      dataIndex: 'quantity',
      width: '8%',
    },
    {
      title: '供应商',
      dataIndex: 'supplierName',
      key: 'supplierName',
      width: 150,
      render: (_, record) => <span>{record.psoOrder?.supplier?.sellerLoginId}</span>
    },
    {
      title: '国内物流',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => (
        <span>{getCrossOrderLogisticsStatus(record.crossOrderProduct?.logisticsStatus)}</span>
      ),
      width: 120, // 显式设置列宽度
    },
    // {
    //   title: '分仓类型',
    //   dataIndex: 'receiptNo',
    //   key: 'receiptNo',
    //   width: 160, // 显式设置列宽度
    //   render: (text, record) => (
    //     <span>纺织品</span>
    //   ),
    // },
    {
      title: '仓号',
      dataIndex: 'receiptNo',
      key: 'receiptNo',
      width: 160, // 显式设置列宽度
      render: (text, record) => (
        <span>{warehouseReceipts.find(warehouseReceipt => warehouseReceipt.id === record.receiptId)?.receiptNo}</span>
      ),
    },
    {
      title: '操作',
      key: 'action',
      fixed: 'right',
      render: (_, record) => (
        <div style={{ display: 'flex' }}>
          {
            record.crossOrderProduct?.logisticsStatus >= 2 && record.crossOrderProduct?.logisticsStatus < 8 && (
              <div>
                <LogisticsTrackingPopup id={record.id} />
              </div>
            )
          }
          {
            record.receiptId && (<Button type='link' onClick={() => handleDownloadReceiptItemPdf(record.receiptItemId)}>下载面单</Button>)
          }
        </div>

      ),
      width: 120, // 显式设置列宽度
    },
  ];


  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
    getCheckboxProps: (record) => {
      console.log(record);
      let disabled = false;
      if (!!record.receiptId || (record.crossOrderProduct?.logisticsStatus >= 2 && record.crossOrderProduct?.logisticsStatus < 8)) {
        disabled = true;
      } else if (selectedRowKeys.length > 0) {
        const orderProduct = orderProducts.find(orderProduct => orderProduct.id === selectedRowKeys[0]);
        disabled = true;
        if (orderProduct && orderProduct.psoOrder.supplier.sellerLoginId === record.psoOrder.supplier.sellerLoginId) {
          disabled = false;
        }
      }

      return {
        disabled,
      }
    },
  };

  const handleCreateWarehouseReceipt = async (data, warehouseReceiptCategoryId) => {
    console.log(data, 'data...')
    const resp = await logisticsApi.createWarehouseReceipt({
      psoOrderId: data[0].psoOrder.id,
      warehouseReceiptCategoryId,
      orderProductDataList: data.map(psoOrderProduct => ({
        psoOrderProductId: psoOrderProduct.id,
        boxCount: psoOrderProduct.boxCount,
        boxPackQty: psoOrderProduct.boxPackQty,
        boxWeight: psoOrderProduct.boxWeight,
        boxWidth: psoOrderProduct.boxWidth,
        boxLength: psoOrderProduct.boxLength,
        boxHeight: psoOrderProduct.boxHeight
      }))
    });

    console.log(resp);

    setWarehouseReceiptOverlay(false);
    setSelectedRowKeys([]);

    onReload && onReload();
  }

  const handleDownload = async () => {
    try {
      const response = await fetch("/api/v1/download", {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to download PDF");
      }

      const blob = await response.blob();
      saveAs(blob, "receipt.pdf"); // 使用 file-saver 下载 PDF
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  console.log(orderProducts.filter(orderProduct => selectedRowKeys.includes(orderProduct.id)), selectedRowKeys, '......sssss.')
  return (
    <>
      <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'flex-end' }}>
        <Button type="primary" style={{ marginRight: '10px' }} onClick={() => selectedRowKeys.length > 0 && setWarehouseReceiptOverlay(true)}>
          创建入仓单
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={orderProducts}
        pagination={false}
        rowSelection={rowSelection}
        rowKey="id"
      />
      {
        warehouseReceiptOverlay && (
          <CreateWarehouseReceiptOverlay
            visible={warehouseReceiptOverlay}
            psoOrderProducts={orderProducts.filter(orderProduct => selectedRowKeys.includes(orderProduct.id))}
            warehouseReceiptCategories={warehouseReceiptCategories}
            onSubmit={handleCreateWarehouseReceipt}
            onCancel={() => setWarehouseReceiptOverlay(false)}
          />
        )
      }
    </>
  )

}

const IntlLogisticsWarehouseReceiptStatus = {
  PENDING: '待处理',
  ORDERED: '已下单',
  IN_WAREHOUSE: '已入库',
  IN_TRANSIT: '转运中',
  DESTPORT_ARRIVED: '到达目的港',
  CLEARANCE: '清关中',
  LOCAL_SHIPPING: '本地配送',
  DELIVERED: '已送达',
  CANCELLED: '已取消',
  EXCEPTION: '异常状态',
};

const BatchWarehouseReceiptOrderOverlay = ({ visible, warehouseReceipts = [], onClose }) => {
  const handleBatchOrder = () => {
  }

  return (
    <Modal
      title="确认下单"
      visible={visible}
      onOk={handleBatchOrder} // Handle batch order on confirm
      onCancel={onClose} // Close the modal on cancel
    >
      <p>
        确认对以下仓单进行下单：
        <ul>
          {warehouseReceipts
            .map((receipt) => (
              <li key={receipt.receiptNo}>{receipt.receiptNo}</li>
            ))}
        </ul>
      </p>
    </Modal>
  )
}


const WarehouseReceiptTable = ({ warehouseReceipts = [], poOrderId, onReload }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [batchOrderVisible, setBatchOrderVisible] = useState(false);

  const handleConfirmWarehouseReceiptOrder = async record => {
    const resp = await logisticsApi.submitWarehouseReceipts({
      poOrderId,
      warehouseReceiptIds: [record.id],
    });

    console.log(resp);

    onReload && onReload();
  }

  const handleDeleteWarehouseReceiptOrder = async record => {
    const resp = await logisticsApi.deleteWarehouseReceipts({
      poOrderId,
      warehouseReceiptIds: [record.id],
    })

    console.log(resp);

    onReload && onReload();
  }

  const handleGetWarehouseReceiptTracks = async record => {
    const resp = await logisticsApi.getWarehouseReceiptTracks(record.id);

    console.log(resp);
  }

  const warehouseReceiptColumns = [
    {
      title: '仓号',
      dataIndex: 'receiptNo',
      key: 'receiptNo',
      render: (text) => <a>{text}</a>,
    },
    {
      title: '仓单状态',
      dataIndex: 'status',
      key: 'status',
      render: (text) => {
        // Map the status to its corresponding tag color and name from the enum
        const statusName = IntlLogisticsWarehouseReceiptStatus[text] || text;
        const statusColor =
          text === 'DELIVERED' ? 'green' :
            text === 'CANCELLED' ? 'red' :
              text === 'IN_TRANSIT' ? 'orange' :
                'default'; // Default color if no match

        return <Tag color={statusColor}>{statusName}</Tag>;
      },
    },
    {
      title: '仓单分类',
      dataIndex: 'warehouseReceiptCategory',
      key: 'warehouseReceiptCategory',
      render: (text, record) => <span>{`${record.receiptCategory?.categoryName}(${record.receiptCategory?.priceValue}/${record.receiptCategory?.priceUnit})`}</span>,
    },
    {
      title: '商品总数量',
      dataIndex: 'totalItemsQuantity',
      key: 'totalItemsQuantity',
      render: (text) => <span>{text}</span>,
    },
    {
      title: '总体积(m³)',
      dataIndex: 'totalItemsBoxVolume',
      key: 'totalItemsBoxVolume',
      render: (text, record) => <span>{record.totalItemsBoxVolume / 1000}</span>,
    },
    {
      title: '毛重(kg)',
      dataIndex: 'totalItemsWeight',
      key: 'totalItemsWeight',
      render: (text) => <span>{text}</span>,
    },
    {
      title: '总运费计算(RMB)',
      dataIndex: 'totalItemsFreightCost',
      key: 'totalItemsFreightCost',
      render: (text) => <span>¥{text}</span>,
    },
    {
      title: '实际运费(RMB)',
      dataIndex: 'actualFreightCost',
      key: 'actualFreightCost',
      render: (text) => <span>¥{text}</span>,
    },
    {
      title: '总货值(RMB)',
      dataIndex: 'totalItemsPrice',
      key: 'totalItemsPrice',
      render: (text) => <span>¥{text}</span>,
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => <Space>
        {
          record.status === 'PENDING' && (
            <Popconfirm
              title={`是否对仓单 ${record.receiptNo} 下单？`}
              cancelText="取消"
              okText="确认"
              onConfirm={() => handleConfirmWarehouseReceiptOrder(record)}
            >
              <a>确认下单</a>
            </Popconfirm>
          )
        }
        {
          record.status !== 'PENDING' && (
            <IntlLogisticsTrackingPopup id={record.id} receiptNo={record.receiptNo} />
          )
        }
        {
          (record.status === 'PENDING' || record.status === 'ORDERED') && (
            <Popconfirm
              title={`是否对仓单 ${record.receiptNo} 作废删除？`}
              cancelText="取消"
              okText="确认"
              onConfirm={() => handleDeleteWarehouseReceiptOrder(record)}
            >
              <a>作废删除</a>
            </Popconfirm>
          )
        }
      </Space>,
    },
  ];

  return (
    <div style={{ marginTop: 42 }}>
      <RadioGroup className="ant-radio-group ant-radio-group-outline">
        <Radio.Button value="a" disabled>
          <span style={{ color: 'red', marginRight: '5px' }}>
            [{selectedRowKeys.length}]
          </span>
          <span>已选择仓单</span>
        </Radio.Button>
        <Radio.Button value="reload" onClick={onReload}>
          <span>重新加载</span>
        </Radio.Button>
        <Radio.Button value="delete">
          <span>批量删除</span>
        </Radio.Button>
        <Radio.Button value="order" onClick={() => selectedRowKeys.length > 0 && setBatchOrderVisible(true)}>
          <span>批量下单</span>
        </Radio.Button>
      </RadioGroup>
      <Table
        columns={warehouseReceiptColumns}
        dataSource={warehouseReceipts}
        rowKey="receiptNo"
        pagination={false}
        expandable={{
          expandedRowRender: (record) => (
            <Table
              size='small'
              columns={[
                { title: '商品名', dataIndex: 'name', key: 'name', width: '30%' },
                {
                  title: 'SKU',
                  dataIndex: 'skuInfo',
                  key: 'skuInfo',
                  render: (_, record) => (<span>{record.skuInfo}</span>)
                },
                { title: '箱数', dataIndex: 'boxCount', key: 'boxCount' },
                { title: '每箱数量', dataIndex: 'boxPackQty', key: 'boxPackQty' },
                { title: '总数量', dataIndex: 'quantity', key: 'quantity' },
                {
                  title: '每箱体积(m³)',
                  dataIndex: 'boxVolume',
                  key: 'boxVolume',
                  render: (_, record) => (
                    <>
                      <span>{`${record.boxVolume / 1000}`}</span>
                      <Popover
                        title="箱子尺寸 (cm)"
                        trigger={"click"}
                        content={
                          <div>
                            <p>长: {record.boxLength} cm</p>
                            <p>宽: {record.boxWidth} cm</p>
                            <p>高: {record.boxHeight} cm</p>
                          </div>
                        }
                      >
                        <Tooltip title="查看尺寸" placement='right'>
                          <InfoCircleOutlined style={{ marginLeft: 5, color: '#1890ff', cursor: 'pointer' }} />
                        </Tooltip>
                      </Popover>
                    </>

                  )
                },
                {
                  title: '总体积(m³)',
                  dataIndex: 'dimension',
                  key: 'dimension',
                  render: (_, record) => (
                    <span>{`${record.totalBoxVolume / 1000}`}</span>
                  )
                },
                {
                  title: '毛重(kg)',
                  dataIndex: 'totalWeight',
                  key: 'totalWeight',
                },
                {
                  title: '货值(RMB)',
                  dataIndex: 'totalPrice',
                  key: 'totalPrice',
                  render: (text) => (<span>¥{text}</span>)
                },
                {
                  title: '运费计算(RMB)',
                  dataIndex: 'freightCost',
                  key: 'freightCost',
                  render: (text) => (<span>¥{text}</span>)
                },
              ]}
              dataSource={record.items}
              rowKey="name"
              pagination={false}
            />
          ),
          rowExpandable: (record) => record.items && record.items.length > 0,
        }}
        rowSelection={{
          selectedRowKeys: selectedRowKeys,  // Keep track of selected rows
          onChange: (selectedRowKeys) => {
            setSelectedRowKeys(selectedRowKeys);
          },
        }}
      />
      <BatchWarehouseReceiptOrderOverlay
        visible={batchOrderVisible}
        warehouseReceipts={warehouseReceipts.filter(warehouseReceipt => selectedRowKeys.includes(warehouseReceipt.receiptNo))}
        onClose={() => setBatchOrderVisible(false)}
      />
    </div>
  )
}

const WarehouseManagement = () => {
  const [warehouseReceipts, setWarehouseReceipts] = useState([]);
  const [warehouseReceiptCategories, setWarehouseReceiptCategories] = useState([]);
  const [purchaseOrder, setPurchaseOrder] = useState({});
  const [orderProducts, setOrderProducts] = useState([]);
  let { poOrderId } = useParams();

  useEffect(() => {
    fetchPruchaseOrder();
  }, []);

  useEffect(() => {
    logisticsApi.getWarehouseReceiptCategories()
      .then(resp => {
        setWarehouseReceiptCategories(restructureCategories(resp));
      });

  }, []);

  function restructureCategories(data) {
    // 创建一个对象来存储每个父级类别的名称，以便后续引用
    const parentMap = {};

    // 遍历数据，提取需要的分类层级
    return data
      .filter(item => item.level === 2) // 只保留 level = 2 的项
      .map(item => {
        const parentCategory = data.find(parent => parent.id === item.parentId);
        // 构造文本和对应的 value
        const text = parentCategory ? `${parentCategory.categoryName} -> ${item.categoryName}(${item.priceValue}/${item.priceUnit})` : item.categoryName;
        const value = item.id;

        return { text, value };
      });
  }

  const fetchPruchaseOrder = () => {
    purchaseOrderApi.getPurchaseOrder(poOrderId)
      .then(purchaseOrder => {
        setWarehouseReceipts(purchaseOrder.warehouseReceipts ?? []);
        setPurchaseOrder(purchaseOrder);

        const orderProducts = purchaseOrder.purchaseSupplierOrders.reduce((orderProducts, purchaseSupplierOrder) => {
          const psoOrderProducts = purchaseSupplierOrder.orderProducts.map(orderProduct => ({
            ...orderProduct,
            psoOrder: purchaseSupplierOrder,
          }))
          return [...orderProducts, ...psoOrderProducts];
        }, []);
        setOrderProducts(orderProducts);

      })
      .catch(console.log);
  }



  return (
    <>
      <div className="page_head">
        <div id="page_head_content" style={{ height: '72px' }}>
          <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
            <Col span={12}>
              <div className="flex-center-vertical">
                <h3 className="ant-typography ant-typography-ellipsis ant-typography-single-line ant-typography-ellipsis-single-line text-24" style={{ margin: 0, lineHeight: '72px', minHeight: '72px', padding: '0px 8px', flexGrow: 1 }}>
                  分仓管理
                </h3>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className='inner-content relative px-[16px]' style={{ paddingTop: '102px', paddingBottom: '64px' }}>
        {/* 总采购单信息 */}
        <Card title="采购单信息" bordered={false} style={{ marginBottom: '20px' }}>
          <Descriptions bordered column={2}>
            <Descriptions.Item label="采购单号">{purchaseOrder.orderNo}</Descriptions.Item>
            <Descriptions.Item label="销售单号">  {[
              ...new Set(
                purchaseOrder.purchaseSupplierOrders
                  ?.map(purchaseSupplierOrder => purchaseSupplierOrder.saleOrderIds)
                  .reduce((sum, item) => sum.concat(item), [])
              ),
            ]}</Descriptions.Item>
            <Descriptions.Item label="创建时间">{formatDateTime(purchaseOrder.createdTime)}</Descriptions.Item>
            <Descriptions.Item label="采购单状态">
              <Tag color="blue">{procurementOrder.status}</Tag>
            </Descriptions.Item>
            <Descriptions.Item label="物流服务商">
              {purchaseOrder.intlLogisticsProvider?.providerName}
            </Descriptions.Item>
            <Descriptions.Item label="供应商发货商品">
              10/16
            </Descriptions.Item>
          </Descriptions>
        </Card>
        {/* 添加按钮 */}

        <OrderProductTable orderProducts={orderProducts} warehouseReceipts={warehouseReceipts} onReload={() => fetchPruchaseOrder()} warehouseReceiptCategories={warehouseReceiptCategories} />

        {/* 仓单表格 */}
        <WarehouseReceiptTable warehouseReceipts={warehouseReceipts} poOrderId={purchaseOrder.id} onReload={() => fetchPruchaseOrder()} />
        {/* <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 24 }}>
          <Button type="primary" onClick={handleSubmitWarehouseReceipts}>确认下单</Button>
        </div> */}

      </div>
    </>
  );
};

export default WarehouseManagement;
