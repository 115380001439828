import React from "react";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

const SaleOrderProductStatus = ({ status }) => {
  const { t } = useTranslation();

  const getStatusText = (status) => {
    switch (status) {
      case 'NEW':
        return t('status.saleOrderProductStatus.NEW');
      case 'PENDING':
        return t('status.saleOrderProductStatus.PENDING');
      case 'PURCHASE_PENDING':
        return t('status.saleOrderProductStatus.PURCHASE_PENDING');
      case 'PURCHASING':
        return t('status.saleOrderProductStatus.PURCHASING');
      case 'SHIPPED':
        return t('status.saleOrderProductStatus.SHIPPED');
      case 'IN_TRANSIT':
        return t('status.saleOrderProductStatus.IN_TRANSIT');
      case 'DELIVERED':
        return t('status.saleOrderProductStatus.DELIVERED');
      case 'COMPLETED':
        return t('status.saleOrderProductStatus.COMPLETED');
      case 'CANCELLED':
        return t('status.saleOrderProductStatus.CANCELLED');
      case 'REFUNDED':
        return t('status.saleOrderProductStatus.REFUNDED');
      case 'RETURNED':
        return t('status.saleOrderProductStatus.RETURNED');
      case 'FAILED':
        return t('status.saleOrderProductStatus.FAILED');
      default:
        return t('status.saleOrderProductStatus.UNKNOWN');
    }
  };

  return <Text>{getStatusText(status)}</Text>;
};

export default SaleOrderProductStatus;