import React, { useState, useEffect } from 'react';
import { Popover, Tag, Timeline, Typography, Button } from 'antd';
import logisticsApi from '../../servapi/logistics';
import saleOrderApi from '../../servapi/sale_order';

const { Text } = Typography;

const IntlLogisticsTrackingPopup = ({ id, idType = 'warehouseReceiptId', receiptNo }) => {
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };

  useEffect(() => {
    if (!visible || data.length > 0) {
      return;
    }

    if (idType === 'saleOrderProductId') {
      saleOrderApi.getSaleOrderProductIntlLogisticsTraces(id)
      .then(resp => {
        if (resp && resp.length > 0) {
          setData(resp)
        }
      })
      .catch(console.log);
    }


    if (idType === 'warehouseReceiptId') {
      logisticsApi.getWarehouseReceiptTracks(id)
      .then(resp => {
        if (resp && resp.length > 0) {
          setData(resp)
        }
      })
      .catch(console.log);
    }


  }, [id, visible]);

  // 按时间倒序排序 logisticsSteps
  const sortedLogisticsSteps = data.sort((a, b) => new Date(b.dates) - new Date(a.dates));
  const content = (
    <div style={{ maxWidth: '500px', maxHeight: '600px', overflowY: 'auto' }}>
      {/* 物流信息详情 */}
      <div style={{ marginBottom: '16px' }}>
        <Text strong>仓单编号: </Text><Text>{receiptNo}</Text><br />
      </div>

      {/* 物流追踪时间线 */}
      <Timeline>
        {sortedLogisticsSteps?.map((step, index) => (
          <Timeline.Item key={index}>
            <div>{step.dates}</div>
            <div>{step.content}</div>
          </Timeline.Item>
        ))}
      </Timeline>
    </div>
  );

  return (
    <Popover
      content={content}
      title="国际物流轨迹"
      trigger="click"
      visible={visible}
      onVisibleChange={handleVisibleChange}
      placement="bottom"
    >
      <a style={{ cursor: 'pointer' }}>查询轨迹</a>
    </Popover>
  );
};

export default IntlLogisticsTrackingPopup;
