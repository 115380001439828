import React, { useState, useEffect } from 'react';
import { Popover, Tag, Timeline, Typography, Button } from 'antd';
import purchaseOrderApi from '../../servapi/purchase_order';
import saleOrderApi from '../../servapi/sale_order';
import logisticsApi from '../../servapi/logistics';

const { Text } = Typography;

const LogisticsTrackingPopup = ({ id, idType = 'psoOrderProductId' }) => {
  const [data, setData] = useState();
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };

  useEffect(() => {
    if (!visible || data) {
      return;
    }

    if (idType === 'psoOrderProductId') {
      purchaseOrderApi.getPurchaseOrderProductLogisticsTraces(id)
      .then(resp => {
        if (resp && resp.length > 0) {
          setData(resp[0])
        }
      })
      .catch(console.log);
    }

    if (idType === 'saleOrderProductId') {
      saleOrderApi.getSaleOrderProductLogisticsTraces(id)
        .then(resp => {
          if (resp && resp.length > 0) {
            setData(resp[0])
          }
        })
        .catch(console.log);
    }

    if (idType === 'logisticsId') {
      logisticsApi.getLogisticsTracesByLogisticsId(id)
        .then(resp => {
          if (resp && resp.length > 0) {
            setData(resp[0])
          }
        })
        .catch(console.log);
    }



    
  }, [id, idType, visible]);

    // 按时间倒序排序 logisticsSteps
  const sortedLogisticsSteps = data?.logisticsSteps?.sort((a, b) => new Date(b.acceptTime) - new Date(a.acceptTime));

  const content = (
    <div style={{ maxWidth: '300px', maxHeight: '400px', overflowY: 'auto' }}>
      {/* 物流信息详情 */}
      <div style={{ marginBottom: '16px' }}>
        <Text strong>物流编号: </Text><Text>{data?.logisticsId}</Text><br />
        <Text strong>物流单编号: </Text><Text>{data?.logisticsBillNo}</Text><br />
        <Text strong>订单编号: </Text><Text>{data?.crossOrderId}</Text>
      </div>
      
      {/* 物流追踪时间线 */}
      <Timeline>
        {sortedLogisticsSteps?.map((step, index) => (
          <Timeline.Item key={index}>
            <div>{step.acceptTime}</div>
            <div>{step.remark}</div>
          </Timeline.Item>
        ))}
      </Timeline>
    </div>
  );

  console.log(data);

  return (
    <Popover
      content={content}
      title="物流追踪信息"
      trigger="click"
      visible={visible}
      onVisibleChange={handleVisibleChange}
      placement="bottom"
    >
      <Button type="link" style={{ cursor: 'pointer'}}>查询物流</Button>
    </Popover>
  );
};

export default LogisticsTrackingPopup;
