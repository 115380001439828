import React, { useState, useEffect } from 'react';
import { Card, Button, Descriptions, Divider, DatePicker, Table, Timeline, Tag, Tabs, Typography, Row, Col, Select, Badge, Input, Modal, message, Pagination, Checkbox, Popover, InputNumber, Form } from 'antd';
import { EditOutlined, ClockCircleOutlined, UserOutlined, FormOutlined, UpOutlined, DownOutlined, CheckCircleOutlined } from '@ant-design/icons';
import './SaleOrderList.less';
import saleOrderApi from '../servapi/sale_order';
import shopApi from '../servapi/shop';
import { useTranslation } from 'react-i18next';
import SaleOrderProductStatus from './component/status/SaleOrderProductStatus';
import CreateCustomSaleOrderOverlay from './component/CreateCustomSaleOrderOverlay';
import { constructAddress } from '../util/addr';
import purchaseOrderApi from '../servapi/purchase_order';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { getCurrencySymbol } from '../util/currency';
import LogisticsTrackingPopup from './component/LogisticsTraceOverlay';
import IntlLogisticsTrackingPopup from './component/IntlLogisticsTraceOverlay';

const { Text, Paragraph } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

function formatDate(dateString) {
  const date = new Date(dateString);

  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}



const SaleOrderStatusText = ({ status }) => {
  const { t } = useTranslation();
  const getStatusText = (status) => {
    switch (status) {
      case 'NEW':
        return t("status.saleOrderStatus.NEW");
      case 'PENDING_CONFIRM':
        return t("status.saleOrderStatus.PENDING_CONFIRM");
      case 'CONFIRMED':
        return t("status.saleOrderStatus.CONFIRMED");
      case 'PROCESSING':
        return t("status.saleOrderStatus.PROCESSING");
      case 'COMPLETED':
        return t("status.saleOrderStatus.COMPLETED");
      case 'CANCELLED':
        return t("status.saleOrderStatus.CANCELLED");
      case 'ON_HOLD':
        return t("status.saleOrderStatus.ON_HOLD");
      case 'REFUNDED':
        return t("status.saleOrderStatus.REFUNDED");
      case 'RETURNED':
        return t("status.saleOrderStatus.RETURNED");
      case 'FAILED':
        return t("status.saleOrderStatus.FAILED");
      default:
        return t("status.saleOrderStatus.UNKNOW");
    }
  };

  return <Text>{getStatusText(status)}</Text>;
};

const OrderTotals = ({ currency, orderTotals = [] }) => {
  const { t } = useTranslation();

  const getOrderTotalTypeAmount = (orderTotalType) => {
    return orderTotals.find(orderTotal => orderTotal.totalType === orderTotalType);
  }

  const subTotal = getOrderTotalTypeAmount('WP_TOTAL');

  const freightCost = getOrderTotalTypeAmount('FREIGHT_COST');

  const additionalFee = getOrderTotalTypeAmount('ADDITIONAL_FEE');

  const serviceFee = getOrderTotalTypeAmount('SERVICE_FEE');

  const total = getOrderTotalTypeAmount('TOTAL');

  return (
    <div style={{ paddingRight: 24 }}>
      <Descriptions size="small" column={1} style={{ height: '100%', paddingTop: 8, paddingLeft: 8, }}>
        {
          subTotal && (
            <Descriptions.Item label={"SubTotal"} style={{ float: 'right' }}>
              <Text strong style={{ color: 'blue' }}>
                <span>{getCurrencySymbol(currency)}{subTotal.value}</span>
              </Text>
            </Descriptions.Item>
          )
        }
        {
          freightCost && (
            <Descriptions.Item label={"Freight Cost"} style={{ float: 'right' }}>
              <Text strong style={{ color: 'blue' }}>
                <span>{getCurrencySymbol(currency)}{freightCost.value}</span>
              </Text>
            </Descriptions.Item>
          )
        }
        {
          additionalFee && (
            <Descriptions.Item label={"Additional Fee"} style={{ float: 'right' }}>
              <Text strong style={{ color: 'blue' }}>
                <span>{getCurrencySymbol(currency)}{additionalFee.value}</span>
              </Text>
            </Descriptions.Item>
          )
        }
        {
          serviceFee && (
            <Descriptions.Item label={"Service Fee"} style={{ float: 'right' }}>
              <Text strong style={{ color: 'blue' }}>
                <span>{getCurrencySymbol(currency)}{serviceFee.value}</span>
              </Text>
            </Descriptions.Item>
          )
        }
        {
          total && (
            <Descriptions.Item label={t("shopOrders.orderDetail.summary.totalAmount")} style={{ float: 'right' }}>
              <Text strong style={{ color: 'blue' }}>
                <span>{getCurrencySymbol(currency)}{total.value}</span>
              </Text>
            </Descriptions.Item>
          )
        }

      </Descriptions>
    </div>
  )
}

const FeePaymentOverlay = ({ onCancel, orderDetail, feePayment, currency, orderTotals = [], onReload }) => {
  const getOrderTotalTypeAmount = (orderTotalType) => {
    return orderTotals.find(orderTotal => orderTotal.totalType === orderTotalType)?.value ?? 0;
  }

  const getOrderTotalTypeExtraNote = (orderTotalType) => {
    return orderTotals.find(orderTotal => orderTotal.totalType === orderTotalType)?.extraNote ?? '';
  }

  const getOrderTotalType = (orderTotalType) => {
    return orderTotals.find(orderTotal => orderTotal.totalType === orderTotalType);
  }

  const [additionalFee, setAdditionalFee] = useState(getOrderTotalTypeAmount('ADDITIONAL_FEE'))
  const [serviceFee, setServiceFee] = useState(getOrderTotalTypeAmount('SERVICE_FEE'));
  const [additionalFeeNote, setAdditionalFeeNote] = useState(getOrderTotalTypeExtraNote('ADDITIONAL_FEE'));
  const [serviceFeeNote, setServiceFeeNote] = useState(getOrderTotalTypeExtraNote('SERVICE_FEE'));
  const [isEditingAdditionalFee, setIsEditingAdditionalFee] = useState(false);
  const [isEditingServiceFee, setIsEditingServiceFee] = useState(false);


  const handleChange = (value, type) => {
    if (type === 'additionalFee') {
      setAdditionalFee(value);
    } else if (type === 'serviceFee') {
      setServiceFee(value);
    }
  };

  const handleNoteChange = (value, type) => {
    if (type === 'additionalFee') {
      setAdditionalFeeNote(value);
    } else if (type === 'serviceFee') {
      setServiceFeeNote(value);
    }
  };

  const toggleEdit = (type) => {
    if (type === 'additionalFee') {
      setIsEditingAdditionalFee(!isEditingAdditionalFee);
    } else if (type === 'serviceFee') {
      setIsEditingServiceFee(!isEditingServiceFee);
    }
  };

  const handleConfirmEdit = async (type) => {
    let orderTotalType;
    let amount = 0;
    let extraNote = '';
    if (type === 'additionalFee') {
      orderTotalType = 'ADDITIONAL_FEE';
      amount = additionalFee;
      extraNote = additionalFeeNote;
    } else if (type === 'serviceFee') {
      orderTotalType = 'SERVICE_FEE';
      amount = serviceFee;
      extraNote = serviceFeeNote;
    }

    const resp = await saleOrderApi.updateSaleOrderTotalTypeFee(orderDetail?.shop?.id, orderDetail?.id, {
      orderTotalType,
      amount,
      extraNote,
    });

    console.log(resp);

    if (type === 'additionalFee') {
      setIsEditingAdditionalFee(false);
    } else if (type === 'serviceFee') {
      setIsEditingServiceFee(false);
    }

    onReload && onReload();
  };

  const handleCancelEdit = (type) => {
    if (type === 'additionalFee') {
      setAdditionalFee(getOrderTotalTypeAmount('ADDITIONAL_FEE'));
      setAdditionalFeeNote(getOrderTotalTypeExtraNote('ADDITIONAL_FEE'));
      setIsEditingAdditionalFee(false);
    } else if (type === 'serviceFee') {
      setServiceFee(getOrderTotalTypeAmount('SERVICE_FEE'));
      setServiceFeeNote(getOrderTotalTypeExtraNote('SERVICE_FEE'));
      setIsEditingServiceFee(false);
    }
  };

  const handleConfirm = async () => {
    const hide = message.loading('正在通知客户付款', 0);
    const resp = await saleOrderApi.notifySaleOrderFeePayment(orderDetail?.shop?.id, orderDetail?.id);

    console.log(resp);
    hide();
    message.success('已通知客户付款, 请稍后确认');

    onReload && onReload();
  }

  const freightCost = getOrderTotalType('FREIGHT_COST');

  // Popover content for payment details
  const paymentDetailsContent = () => {
    const payDetails = JSON.parse(feePayment.payDetails);

    return (
      <div>
        <Paragraph><strong>Transaction ID:</strong> {payDetails.transaction_id}</Paragraph>
        <Paragraph><strong>Amount:</strong> {payDetails.currency} {payDetails.amount}</Paragraph>
        <Paragraph><strong>Status:</strong> {payDetails.status_message}</Paragraph>
        <Paragraph><strong>Timestamp:</strong> {payDetails.timestamp}</Paragraph>
      </div>
    )
  };


  return (
    <Modal
      title="支付详情"
      visible={true}
      onCancel={onCancel}
      footer={[
        // Conditionally render buttons based on the payment status
        feePayment.status === 'COMPLETED' ? (
          <Button key="close" onClick={onCancel}>
            关闭
          </Button>
        ) : (
          <>
            <Button key="cancel" onClick={onCancel}>
              取消
            </Button>
            <Button key="confirm" type="primary" onClick={handleConfirm} disabled={freightCost?.statisticsStatus === 'PROGRESSING'}>
              通知付费
            </Button>
          </>
        ),
      ]}
    >
      <div style={{ paddingBottom: 8 }}>
        <Row gutter={16}>
          <Col span={18}>
            <p><strong style={{ marginRight: 4 }}>国际运费:</strong> {getCurrencySymbol(currency)}{freightCost?.value} {freightCost?.statisticsStatus === 'PROGRESSING' && (<span style={{ color: '#de2e2e' }}>统计中</span>)}</p>
            <p>
              <strong style={{ marginRight: 4 }}>附加费:</strong>
              {getCurrencySymbol(currency)}
              {isEditingAdditionalFee ? (
                <>
                  <InputNumber
                    value={additionalFee}
                    min={0}
                    step={0.01}
                    onChange={(value) => handleChange(value, 'additionalFee')}
                    style={{ width: 100, marginLeft: 8 }}
                  />
                  <Input placeholder="备注" value={additionalFeeNote} onChange={(e) => handleNoteChange(e.target.value, 'additionalFee')} style={{ display: 'inline-block', width: 120, marginLeft: 4 }} />
                  <div>
                    <Button
                      type="link"
                      onClick={() => handleConfirmEdit('additionalFee')}
                      style={{ marginLeft: 8 }}
                    >
                      确认修改
                    </Button>
                    <Button
                      type="link"
                      onClick={() => handleCancelEdit('additionalFee')}
                      style={{ marginLeft: 8 }}
                    >
                      取消
                    </Button>
                  </div>

                </>
              ) : (
                <>
                  {additionalFee} {additionalFeeNote ? `- ${additionalFeeNote}` : ''}
                  {
                    feePayment.status !== 'COMPLETED' && (
                      <EditOutlined
                        onClick={() => toggleEdit('additionalFee')}
                        style={{ marginLeft: 8, cursor: 'pointer' }}
                      />
                    )
                  }

                </>
              )}
            </p>
            {/* 手续费 */}
            <p>
              <strong style={{ marginRight: 4 }}>手续费:</strong>
              {getCurrencySymbol(currency)}
              {isEditingServiceFee ? (
                <>
                  <InputNumber
                    value={serviceFee}
                    min={0}
                    step={0.01}
                    onChange={(value) => handleChange(value, 'serviceFee')}
                    style={{ width: 100, marginLeft: 8 }}
                  />
                  <Input placeholder="备注" value={serviceFeeNote} onChange={(e) => handleNoteChange(e.target.value, 'serviceFee')} style={{ display: 'inline-block', width: 120, marginLeft: 4 }} />
                  <div>
                    <Button
                      type="link"
                      onClick={() => handleConfirmEdit('serviceFee')}
                      style={{ marginLeft: 8 }}
                    >
                      确认修改
                    </Button>
                    <Button
                      type="link"
                      onClick={() => handleCancelEdit('serviceFee')}
                      style={{ marginLeft: 8 }}
                    >
                      取消
                    </Button>
                  </div>

                </>
              ) : (
                <>
                  {serviceFee} {serviceFeeNote ? `- ${serviceFeeNote}` : ''}
                  {
                    feePayment.status !== 'COMPLETED' && (
                      <EditOutlined
                        onClick={() => toggleEdit('serviceFee')}
                        style={{ marginLeft: 8, cursor: 'pointer' }}
                      />
                    )
                  }
                </>
              )}
            </p>
          </Col>
          <Col span={6} style={{ textAlign: 'right' }}>
            <p><strong style={{ marginRight: 4 }}>总金额:</strong> {getCurrencySymbol(currency)}{feePayment.amount}</p>
          </Col>
        </Row>
        <Divider />
        <Row gutter={16}>
          <Col span={12}>
            <p><strong style={{ marginRight: 4 }}>通知状态:</strong> {feePayment.synced ? '已通知' : '待通知'}</p>
            <p><strong style={{ marginRight: 4 }}>最近通知时间:</strong> 2024-12-12</p>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <p><strong style={{ marginRight: 4 }}>支付状态:</strong> {feePayment.status === 'PENDING' ? '待支付' : '已支付'}</p>
            <p><strong style={{ marginRight: 4 }}>支付方式:</strong> {feePayment.payMethod ? feePayment.payMethod : '无'}</p>
          </Col>
        </Row>
        {
          feePayment.payDetails && (
            <Popover content={paymentDetailsContent} title="支付详情" trigger={"click"}>
              <a type="link">
                查看支付详情
              </a>
            </Popover>
          )
        }
      </div>
    </Modal>
  )
}

const LogisticsTracking = ({ id }) => {
  const [data, setData] = useState();

  useEffect(() => {
    if (data) {
      return;
    }
    saleOrderApi.getSaleOrderProductLogisticsTraces(id)
      .then(resp => {
        if (resp && resp.length > 0) {
          setData(resp[0])
        }
      })
      .catch(console.log);
  }, [])

  const sortedLogisticsSteps = data?.logisticsSteps?.sort((a, b) => new Date(b.acceptTime) - new Date(a.acceptTime));

  return (
    <div style={{ maxWidth: '300px', maxHeight: '400px', overflowY: 'auto', width: 300 }}>
      {/* 物流信息详情 */}
      <div style={{ marginBottom: '16px' }}>
        <Text strong>物流编号: </Text><Text>{data?.logisticsId}</Text><br />
        <Text strong>物流单编号: </Text><Text>{data?.logisticsBillNo}</Text><br />
        <Text strong>订单编号: </Text><Text>{data?.crossOrderId}</Text>
      </div>

      {/* 物流追踪时间线 */}
      <Timeline>
        {sortedLogisticsSteps?.map((step, index) => (
          <Timeline.Item key={index}>
            <div>{step.acceptTime}</div>
            <div>{step.remark}</div>
          </Timeline.Item>
        ))}
      </Timeline>
    </div>
  )
}

const IntlLogisticsTracking = ({ id }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (data.length > 0) {
      return;
    }

    saleOrderApi.getSaleOrderProductIntlLogisticsTraces(id)
      .then(resp => {
        if (resp && resp.length > 0) {
          setData(resp)
        }
      })
      .catch(console.log);
  }, []);

  const sortedLogisticsSteps = data.sort((a, b) => new Date(b.dates) - new Date(a.dates));
  return (
    <div style={{ maxWidth: '500px', maxHeight: '600px', overflowY: 'auto', width: 300  }}>

      {/* 物流追踪时间线 */}
      <Timeline style={{ paddingTop: 8}}>
        {sortedLogisticsSteps?.map((step, index) => (
          <Timeline.Item key={index}>
            <div>{step.dates}</div>
            <div>{step.content}</div>
          </Timeline.Item>
        ))}
      </Timeline>
    </div>
  )
}

const LogisticsQueryPopover = ({ record = {} }) => {
  const { t } = useTranslation();
  const [popoverVisible, setPopoverVisible] = useState(false); // State for Popover visibility
  const [selectedTab, setSelectedTab] = useState('logistics'); // State for selected tab

  const handlePopoverVisibleChange = (visible) => {
    setPopoverVisible(visible);
  };

  const handleTabChange = (key) => {
    setSelectedTab(key);
  };

  return (
    <Popover
      content={
        <Tabs defaultActiveKey={selectedTab} onChange={handleTabChange}>
          <TabPane tab={t("shopOrders.orderDetail.tab.logistics")} key="logistics">
            <LogisticsTracking id={record.id} />
          </TabPane>
          <TabPane tab={t("shopOrders.orderDetail.tab.intl_logistics")} key="intl_logistics">
            <IntlLogisticsTracking id={record.id} />
          </TabPane>
        </Tabs>
      }
      title={t("shopOrders.orderDetail.logisticsQuery")}
      visible={popoverVisible}
      onVisibleChange={handlePopoverVisibleChange}
      trigger={"click"}
    >
      <Text ellipsis={{ rows: 3, expandable: false }} className="ant-typography-secondary"><a>{t("shopOrders.orderDetail.logisticsQuery")}</a></Text>
    </Popover>
  )
}

const OrderDetail = ({ orderDetail, onConfirm, onConfirmPurchase, onReload }) => {
  const { t } = useTranslation();
  const [showAll, setShowAll] = useState(false);
  const [extraFeeChecked, setExtraFeeChecked] = useState(orderDetail.feePay);
  const [extraFee, setExtraFee] = useState(orderDetail.feePayAmount || 0);
  const [extraFeeNote, setExtraFeeNote] = useState(orderDetail.feePayNote || '');
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [feePaymentOverlay, setFeePaymentOverlay] = useState(false);
  const handleCheckboxChange = async (e) => {

    if (e.target.checked) {
      setExtraFeeChecked(e.target.checked);
      setIsPopoverVisible(true);
    } else {
      const hide = message.loading(t('shopOrders.message.beingUpdateAdditionalFee'), 0);
      const resp = await saleOrderApi.updateSaleOrderFeePay(orderDetail.shop.id, orderDetail.id, {
        feePay: false,
        feePayAmount: 0,
      });
      setExtraFeeChecked(e.target.checked);
      setExtraFee(0)
      console.log(resp);
      hide();
      message.success(t('shopOrders.message.cancelledAdditionalFee'));
    }
  };

  const handleFeeChange = (e) => {
    setExtraFee(e);
  };

  const handleFeeNoteChange = e => {
    setExtraFeeNote(e.target.value);
  }

  const handleConfirm = async () => {
    // 在这里处理保存费用的逻辑
    // console.log('费用已保存:', extraFee);
    const hide = message.loading(t('shopOrders.message.beingUpdateAdditionalFee'), 0);
    const resp = await saleOrderApi.updateSaleOrderFeePay(orderDetail.shop.id, orderDetail.id, {
      feePay: extraFeeChecked,
      feePayAmount: extraFee,
      feePayNote: extraFeeNote,
    })

    console.log(resp);
    hide();
    message.success(t('shopOrders.message.updatedAdditionalFee'));
    setIsPopoverVisible(false);
  };

  const handleCancel = () => {
    setIsPopoverVisible(false);
  };


  const columns = [
    {
      title: t("shopOrders.orderDetail.table.product"),
      dataIndex: 'description',
      key: 'description',
      width: '25%',
      render: (text, record) => (
        <div className="d-flex items-start" style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ float: 'left', width: '60px', height: '60px', marginRight: '16px' }}>
            <img src={record.productImage} alt="" style={{ width: '100%', height: '100%', borderRadius: '0px', border: '1px solid #eee' }} />
          </div>
          <div style={{ maxWidth: 520 }}>
            <Link to={`/product/detail/${record.outProductId}`} target="_blank">
              <Text ellipsis={{ rows: 2, expandable: false }}><span>{record.productName}</span></Text>
            </Link>
            {/* <Text ellipsis={{ rows: 2, expandable: false, width: 320 }}>
              <a href={record.productLink} target="_blank" rel="noopener noreferrer">{record.productName}</a>
            </Text> */}
          </div>
        </div>
      )
    },
    {
      title: t("shopOrders.orderDetail.table.sku"),
      dataIndex: 'skuCode',
      key: 'skuCode',
      width: '20%',
    },
    {
      title: t("shopOrders.orderDetail.table.price"),
      dataIndex: 'price',
      key: 'price',
      width: '10%',
      render: (text, record) => (
        <span style={{ display: 'table-cell', verticalAlign: 'middle' }}>{getCurrencySymbol(orderDetail.currency)}{record.price}</span>
      )
    },
    {
      title: t("shopOrders.orderDetail.table.quantity"),
      dataIndex: 'quantity',
      key: 'quantity',
      width: '10%',
      render: (text, record) => (
        <span>{record.productQuantity}</span>
      )
    },
    {
      title: t("shopOrders.orderDetail.table.status"),
      dataIndex: 'status',
      key: 'status',
      width: '15%',
      render: (text, record) => (
        <span><SaleOrderProductStatus status={record.status} /></span>
      )
    },
    {
      title: t("shopOrders.orderDetail.table.details"),
      dataIndex: 'details',
      key: 'details',
      width: '15%',
      render: (text, record) => (<LogisticsQueryPopover record={record} />)
    },
  ];

  const dataSource = showAll ? orderDetail.orderProducts : orderDetail.orderProducts.slice(0, 3);

  return (
    <Card style={{ marginTop: 16, minHeight: 200 }} bodyStyle={{ padding: 0 }}>
      <Card bodyStyle={{ padding: 0 }}>
        <div className="lkVn3iOw">
          <Card bordered style={{ border: 0 }}
          >
            <Card.Meta
              style={{ display: 'flex' }}
              title={
                <div className="aHPEc5kL" style={{ display: 'flex', position: 'relative' }}>
                  <div style={{ marginRight: 8 }}>
                    <strong>{formatDate(orderDetail.createdTime)}</strong>
                  </div>
                  <div style={{ marginRight: 8 }}>{t("shopOrders.orderDetail.orderNo")}：&nbsp;{orderDetail.orderNo}</div>
                  {
                    orderDetail.orderStatus === 'COMPLETED' && (
                      <CheckCircleOutlined style={{ color: '#52c41a', fontSize: 16, marginLeft: 4  }}/>
                    )
                  }
                </div>
              }
              description={
                <div>
                  {/* <Button style={{ width: 100 }}>编 辑</Button> */}
                  {/* {
                    orderDetail.orderStatus !== 'PENDING_CONFIRM' && (
                      <Button style={{ margin: '0 8px' }}>取 消</Button>
                    )
                  } */}
                  {
                    orderDetail.orderStatus === 'PENDING_CONFIRM' && orderDetail.orderType === 'SALE_ORDER' && (
                      <>
                        <Button style={{ margin: '0 8px' }}>{t('shopOrders.orderDetail.btn.delete')}</Button>
                        <Button type="primary" onClick={() => onConfirm(orderDetail)}>{t('shopOrders.orderDetail.btn.confirm')}</Button>
                      </>
                    )
                  }
                  {
                    orderDetail.orderStatus === 'PENDING_CONFIRM' && orderDetail.orderType === 'MANUAL_ORDER' && (
                      <>
                        <Button style={{ margin: '0 8px' }}>{t('shopOrders.orderDetail.btn.delete')}</Button>
                        <Button type="primary" onClick={() => onConfirmPurchase(orderDetail)}>{t('shopOrders.orderDetail.btn.confirm')}</Button>
                      </>
                    )
                  }
                  {
                    orderDetail.feePayment && orderDetail.feePayment.status === 'COMPLETED' && (
                      <Badge
                        count={<CheckCircleOutlined />}  // Using an icon instead of text
                        style={{
                          color: '#52c41a',  // White icon color
                          position: 'absolute',
                          top: 12,
                          left: -14,
                          fontSize: '24px',  // Adjust icon size
                          padding: '2px 4px',
                          borderRadius: '50%',
                          width: 26,
                        }}
                      >
                        <Button
                          onClick={() => setFeePaymentOverlay(true)}
                          style={{ position: 'relative' }}
                        >
                          Fee Payment
                        </Button>
                      </Badge>
                    )
                  }
                  {
                    orderDetail.feePayment && orderDetail.feePayment.status !== 'COMPLETED' && (
                      <Button
                        onClick={() => setFeePaymentOverlay(true)}
                        style={{ position: 'relative' }}
                      >
                        Fee Payment
                      </Button>
                    )
                  }
                  {
                    feePaymentOverlay && <FeePaymentOverlay orderDetail={orderDetail} feePayment={orderDetail.feePayment} onCancel={() => setFeePaymentOverlay(false)} orderTotals={orderDetail.orderTotals} currency={orderDetail.currency} onReload={onReload} />
                  }

                </div>
              }
            />
          </Card>
          <div style={{ padding: '16px 16px 0', background: '#FAFAFA', borderTop: '1px solid #E8E8E8' }}>
            <Descriptions column={2}>
              <Descriptions.Item label={t("shopOrders.orderDetail.shop")} span={1}>{orderDetail.shop.shopName}</Descriptions.Item>
              <Descriptions.Item label={t("shopOrders.orderDetail.orderNote")} span={3}>{orderDetail.orderNote}</Descriptions.Item>
            </Descriptions>
            <Descriptions column={10}>
              {/* <Descriptions.Item label={t("shopOrders.orderDetail.deliveryAddr")}span={5}>{constructAddress(orderDetail)}</Descriptions.Item> */}
              <Descriptions.Item label={t("shopOrders.orderDetail.shopOrder")} span={5}><Link to={orderDetail.wpOrderLink} target='_blank'>{orderDetail.wpOrderNo}</Link></Descriptions.Item>
              <Descriptions.Item label={t("shopOrders.orderDetail.orderStatus")} span={5}>
                <SaleOrderStatusText status={orderDetail.orderStatus} />
              </Descriptions.Item>
            </Descriptions>
          </div>
          <Divider style={{ margin: 0 }} />
          <Table
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            rowKey="key"
          />
          {
            orderDetail.orderProducts.length > 3 && (
              <div style={{ textAlign: 'center', }}>
                <Button type="link" onClick={() => setShowAll(!showAll)}>
                  {showAll ? <UpOutlined /> : <DownOutlined />}
                  {showAll ? t('shopOrders.orderDetail.hideall') : t('shopOrders.orderDetail.showall', { number: orderDetail.orderProducts.length - 3 })}
                </Button>
              </div>
            )
          }
          <div style={{ paddingRight: 24 }}>
            <OrderTotals orderTotals={orderDetail.orderTotals} currency={orderDetail.currency} />
          </div>
        </div>
      </Card>
    </Card>
  );
};


const SearchOrders = ({ onSync, onSearch, onReset, onSetShop }) => {
  // 定义每个查询条件的状态
  const { t } = useTranslation();
  const [shopId, setShopId] = useState(null);
  const [orderOrProductName, setOrderOrProductName] = useState('');
  const [orderSource, setOrderSource] = useState(null);
  const [recipientName, setRecipientName] = useState('');
  const [status, setStatus] = useState(null);
  const [dateRange, setDateRange] = useState([]);
  const [shops, setShops] = useState([]);

  useEffect(() => {
    shopApi.getShopList()
      .then(shops => {
        setShops(shops);
        handleSetShopId(shops[0].id);
      })
      .catch(console.log);
  }, []);


  // 重置表单
  const resetFilters = () => {
    setShopId(null);
    setOrderOrProductName('');
    setOrderSource(null);
    setRecipientName('');
    setStatus(null);
    setDateRange([]);

    onReset();
  };

  // 处理搜索逻辑
  const handleSearch = () => {
    const searchCriteria = {
      shopId,
      orderOrProductName,
      orderSource,
      recipientName,
      status,
      startDate: dateRange && dateRange[0] ? dateRange[0].format('YYYY-MM-DD') + ' 00:00:00' : null,
      endDate: dateRange && dateRange[1] ? dateRange[1].format('YYYY-MM-DD') + " 23:59:59" : null,
    };
    onSearch(searchCriteria); // 将查询条件传递给父组件或 API 调用
  };

  const ranges = {
    [t('shopOrders.search.timeRanges.recent3day')]: [moment().subtract(3, 'days'), moment()],
    [t('shopOrders.search.timeRanges.recentWeek')]: [moment().subtract(7, 'days'), moment()],
    [t('shopOrders.search.timeRanges.recentMonth')]: [moment().subtract(1, 'months'), moment()],
  };

  const statuses = {
    "NEW": t("status.saleOrderStatus.NEW"),
    "PENDING_CONFIRM": t("status.saleOrderStatus.PENDING_CONFIRM"),
    "CONFIRMED": t("status.saleOrderStatus.CONFIRMED"),
    "PROCESSING": t("status.saleOrderStatus.PROCESSING"),
    "COMPLETED": t("status.saleOrderStatus.COMPLETED"),
    "CANCELLED": t("status.saleOrderStatus.CANCELLED"),
    "ON_HOLD": t("status.saleOrderStatus.ON_HOLD"),
    "REFUNDED": t("status.saleOrderStatus.REFUNDED"),
    "RETURNED": t("status.saleOrderStatus.RETURNED"),
    "FAILED": t("status.saleOrderStatus.FAILED"),
  }

  const handleSetShopId = shopId => {
    setShopId(shopId);
    onSetShop(shopId);
  }

  return (
    <Card title={t("shopOrders.search.title")} style={{ margin: '20px' }}>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div style={{ marginRight: '16px', marginBottom: '8px' }}>
          <div style={{ marginBottom: '6px', color: 'rgba(0, 0, 0, 0.85)' }}>{t("shopOrders.search.shop")}</div>
          <Select
            value={shopId}
            onChange={handleSetShopId}
            placeholder={t("shopOrders.search.all")}
            style={{ width: '260px' }}
            allowClear
          >
            {
              shops.map(shop => (<Option value={shop.id}>{shop.shopName} - {shop.shopUrl}</Option>))
            }
          </Select>
        </div>

        <div style={{ marginRight: '16px', marginBottom: '8px' }}>
          <div style={{ marginBottom: '6px', color: 'rgba(0, 0, 0, 0.85)' }}>{t("shopOrders.search.orderOrProduct")}</div>
          <Input
            value={orderOrProductName}
            onChange={e => setOrderOrProductName(e.target.value)}
            allowClear
            placeholder={t("shopOrders.search.orderOrProduct")}
            style={{ width: '260px' }}
          />
        </div>

        <div style={{ marginRight: '16px', marginBottom: '8px' }}>
          <div style={{ marginBottom: '6px', color: 'rgba(0, 0, 0, 0.85)' }}>{t("shopOrders.search.receiver")}</div>
          <Input
            value={recipientName}
            onChange={e => setRecipientName(e.target.value)}
            allowClear
            placeholder={t("shopOrders.search.receiver")}
            style={{ width: '260px' }}
          />
        </div>

        <div style={{ marginRight: '16px', marginBottom: '8px' }}>
          <div style={{ marginBottom: '6px', color: 'rgba(0, 0, 0, 0.85)' }}>{t("shopOrders.search.status")}</div>
          <Select
            value={status}
            onChange={setStatus}
            placeholder={t("shopOrders.search.all")}
            style={{ width: '180px' }}
            allowClear
          >
            {
              Object.keys(statuses).map(key => <Option value={key}>{statuses[key]}</Option>)
            }
          </Select>
        </div>

        <div style={{ marginRight: '16px', marginBottom: '8px' }}>
          <div style={{ marginBottom: '6px', color: 'rgba(0, 0, 0, 0.85)' }}>{t("shopOrders.search.timeBetween")}</div>
          <RangePicker
            value={dateRange}
            onChange={setDateRange}
            ranges={ranges}
            style={{ width: '256px' }}
          />
        </div>

        <div style={{ marginRight: '16px', marginBottom: '8px', marginTop: 24, display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
          <Button type="primary" style={{ marginRight: 8 }} onClick={handleSearch}>{t("shopOrders.search.search")}</Button>
          <Button style={{ marginRight: 8 }} onClick={resetFilters}>{t("shopOrders.search.clear")}</Button>
        </div>
      </div>
    </Card>
  );
};


export default props => {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [currentShop, setCurrentShop] = useState({
    id: 1,
  });
  const [searchParams, setSearchParams] = useState({});
  const [orderType, setOrderType] = useState('SALE_ORDER');

  React.useEffect(() => {
    fetchSaleOrders({ searchParams, pagination })
  }, [pagination.current, pagination.pageSize, searchParams, orderType, i18n.language])

  const fetchSaleOrders = async ({ searchParams, pagination }) => {
    const pageData = await saleOrderApi.getSaleOrders({
      ...searchParams,
      orderType,
      page: pagination.current - 1,
      size: pagination.pageSize,
    })

    setData(pageData.saleOrders || []);
    setPagination({
      ...pagination,
      current: pageData.number + 1,
      total: pageData.recordsTotal,
    })
  }

  const handleConfirmSaleOrder = async orderDetail => {
    const hide = message.loading(t('shopOrders.message.beingConfirmOrder'), 0);
    const salePoolOrder = await shopApi.confirmSaleOrder(orderDetail.shop.id, orderDetail.id);
    hide();
    message.success(t('shopOrders.message.confirmedOrder'));

    fetchSaleOrders({ searchParams, pagination });
  }

  const handlePurchaseSaleOrder = async orderDetail => {
    const hide = message.loading(t('shopOrders.message.beingCreatePurchaseOrder'), 0);
    const resp = await purchaseOrderApi.createPurchaseOrderFromSaleOrder(orderDetail.id);
    console.log(resp);
    hide();
    message.success(t('shopOrders.message.createdPurchaseOrder'));

    fetchSaleOrders({ searchParams, pagination });
  }

  const handleSyncSaleOrders = async () => {
    const hide = message.loading(t('shopOrders.message.beingSyncOrder'), 0);
    const resp = await saleOrderApi.syncSaleOrders(currentShop.id);
    hide();
    message.success(t('shopOrders.message.syncedOrder'));

    fetchSaleOrders({ searchParams, pagination });
  }

  const handlePageChange = (current, pageSize) => {
    setPagination({
      ...pagination,
      current,
      pageSize,
    })
  };


  const handleCreateManualSaleOrder = async ({ shopId, orderNote, ...params }, products) => {
    if (products.length <= 0) {
      message.error(t('shopOrders.message.mustAddProduct'))
      return;
    }

    const hide = message.loading(t('shopOrders.message.beingCreateManualOrder'), 0);
    const resp = await saleOrderApi.createManualSaleOrder(shopId, {
      billingAddress: params,
      deliveryAddress: params,
      orderNote,
      orderProducts: products.map(product => ({ offerId: product.offerId, sku: `${product.skuId}`, quantity: product.quantity })),
    });


    message.success(t('shopOrders.message.createdManualOrder'));
    hide();
    setVisible(false);

    fetchSaleOrders({ searchParams, pagination });
  }

  const handleReload = () => {
    fetchSaleOrders({ searchParams, pagination });
  }

  return (
    <>
      <div className="page_head">
        <div id="page_head_content" style={{}}>
          <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
            <Col span={24}>
              <div className="flex-center-vertical">
                <h3 className="ant-typography ant-typography-ellipsis ant-typography-single-line ant-typography-ellipsis-single-line text-24" style={{ margin: 0, lineHeight: '72px', minHeight: '72px', padding: '0px 8px', flexGrow: 1 }}>
                  {t("shopOrders.title")}
                </h3>
                {
                  orderType === 'MANUAL_ORDER' && <Button type='primary' onClick={() => setVisible(true)}>{t("shopOrders.btn.create")}</Button>
                }
                {
                  orderType === 'SALE_ORDER' && <Button type="primary" onClick={handleSyncSaleOrders}>{t("shopOrders.btn.sync")}</Button>
                }
              </div>
            </Col>
            <Col span={24}>
              <nav class="page-layout-nav">
                <div class="nav-item"><a aria-current="page" href="javascript:void(0);" class={`${orderType === 'MANUAL_ORDER' ? 'active' : ''}`} onClick={() => setOrderType('MANUAL_ORDER')}>{t("shopOrders.tab.manual")}</a></div>
                <div class="nav-item"><a href="javascript:void(0);" class={`${orderType === 'SALE_ORDER' ? 'active' : ''}`} onClick={() => setOrderType('SALE_ORDER')}>{t("shopOrders.tab.sale")}</a></div>
              </nav>
            </Col>
          </Row>
        </div>
      </div>
      <div className='inner-content relative px-[16px]' style={{ paddingTop: '102px', paddingBottom: '32px' }}>
        <SearchOrders onSync={handleSyncSaleOrders} onSearch={setSearchParams} onReset={() => setSearchParams({})} onSetShop={shopId => setCurrentShop({ id: shopId })} />
        <div className='order-details-list' style={{ margin: 20 }}>
          {
            data.map(orderDetail => <OrderDetail key={orderDetail.id} orderDetail={orderDetail} onConfirm={handleConfirmSaleOrder} onConfirmPurchase={handlePurchaseSaleOrder} onReload={handleReload} />)
          }
        </div>
        <div className="pagination" style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Pagination
            current={pagination.current}
            pageSize={pagination.pageSize}
            total={pagination.total}
            onChange={handlePageChange}
            showSizeChanger
            pageSizeOptions={['5', '10', '20']}
          />
        </div>
        {
          visible && <CreateCustomSaleOrderOverlay onCancel={() => setVisible(false)} onConfirm={handleCreateManualSaleOrder} />
        }

      </div>
    </>
  )
}