import React from "react";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";
const { Text } = Typography;

const PurchaseSupplierOrderPayStatus = ({ status }) => {
  const { t } = useTranslation();
  const getStatusText = (status) => {
    switch (status) {
      case 'PENDING_PAY':
        return t('status.purchaseSupplierOrderPayStatus.PENDING_PAY');
      case 'PARTIAL_PAID':
        return t('status.purchaseSupplierOrderPayStatus.PARTIAL_PAID');
      case 'PAID':
        return t('status.purchaseSupplierOrderPayStatus.PAID');
      case 'CLOSED':
        return t('status.purchaseSupplierOrderPayStatus.CLOSED');
      case 'FAILED':
        return t('status.purchaseSupplierOrderPayStatus.FAILED');
      default:
        return t('status.purchaseSupplierOrderPayStatus.UNKNOWN');
    }
  };

  return <Text>{getStatusText(status)}</Text>;
};


export default PurchaseSupplierOrderPayStatus;