import React, { useEffect, useState } from 'react';
import { Table, Button, Checkbox, Select, Row, Col, Space, Input, Tag, Typography, InputNumber, } from 'antd';
import logisticsApi from '../servapi/logistics';
import purchaseOrderApi from '../servapi/purchase_order';
import productApi from '../servapi/product';
import { EditOutlined, CheckCircleOutlined } from '@ant-design/icons';

const { Option } = Select;
const { Text } = Typography;

const productCategories = ['纺织品类', '家电机器类', '敏感品类'];
const warehouseNumbers = ['仓号1', '仓号2', '仓号3', '仓号4'];

const sampleData = [
  { id: 1, name: '商品A', quantity: 10, category: '', warehouse: '仓号1' },
  { id: 2, name: '商品B', quantity: 10, category: '', warehouse: '仓号2' },
  { id: 3, name: '商品C', quantity: 10, category: '', warehouse: '仓号3' },
  { id: 4, name: '商品D', quantity: 10, category: '', warehouse: '仓号1' },
];

// 假设采购单信息
const orderInfo = {
  orderNumber: 'PO12345',
  status: '待处理',
  createDate: '2025-01-01 12:00:00',
};

const WarehouseManagement = () => {
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [orderProducts, setOrderProducts] = useState([]);
  const [autoAssigned, setAutoAssigned] = useState(false);
  const [warehouseReceiptCategories, setWarehouseReceiptCategories] = useState([]);
  const [warehouseReceipts, setWarehouseReceipts] = useState([]);
  const [purchaseOrder, setPurchaseOrder] = useState({});
  const [editId, setEditId] = useState(null);


  useEffect(() => {
    logisticsApi.getWarehouseReceiptCategories()
      .then(resp => {
        console.log(resp);
        setWarehouseReceiptCategories(restructureCategories(resp));
      });

  }, []);

  useEffect(() => {
    fetchPruchaseOrder();
  }, []);


  const fetchPruchaseOrder = () => {
    purchaseOrderApi.getPurchaseOrder(52)
      .then(purchaseOrder => {
        const orderProducts = purchaseOrder.purchaseSupplierOrders.reduce((orderProducts, purchaseSupplierOrder) => {
          return [...orderProducts, ...purchaseSupplierOrder.orderProducts];
        }, []);

        setOrderProducts(orderProducts);
        setWarehouseReceipts(purchaseOrder.warehouseReceipts??[]);
      })
      .catch(console.log);
  }

  function restructureCategories(data) {
    // 创建一个对象来存储每个父级类别的名称，以便后续引用
    const parentMap = {};

    // 遍历数据，提取需要的分类层级
    return data
        .filter(item => item.level === 2) // 只保留 level = 2 的项
        .map(item => {
            const parentCategory = data.find(parent => parent.id === item.parentId);
            // 构造文本和对应的 value
            const text = parentCategory ? `${parentCategory.categoryName} -> ${item.categoryName}` : item.categoryName;
            const value = item.id;

            return { text, value };
        });
}

  const handleProductSelect = (id) => {
    setSelectedProducts((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((item) => item !== id)
        : [...prevSelected, id]
    );
  };

  const handleWarehouseChange = (id, warehouse) => {
    const updatedProducts = orderProducts.map((product) =>
      product.id === id ? { ...product, warehouse } : product
    );
    setOrderProducts(updatedProducts);
  };

  const handleCategoryChange = (id, category) => {
    const updatedProducts = orderProducts.map((product) =>
      product.id === id ? { ...product, category } : product
    );
    setOrderProducts(updatedProducts);
  };

  // const handleAutoAssignWarehouses = () => {
  //   const updatedProducts = orderProducts.map((product) => ({
  //     ...product,
  //     warehouse: warehouseNumbers[Math.floor(Math.random() * warehouseNumbers.length)],
  //   }));
  //   setOrderProducts(updatedProducts);
  //   setAutoAssigned(true);
  // };

  const handleConfirm = () => {
    // Here, you would send the updated data to the backend for confirmation
    alert('仓号分配已确认');
  };

  const handleReset = () => {
    setOrderProducts(sampleData);
    setSelectedProducts([]);
    setAutoAssigned(false);
  };

  const handleDeleteSelected = () => {
    const updatedProducts = orderProducts.filter((product) => !selectedProducts.includes(product.id));
    setOrderProducts(updatedProducts);
    setSelectedProducts([]);
  };

  const handleUpdateProductSpecification = async (record) => {
    const resp = await productApi.updateProductSpecification(record.product.id, {
      productWidth: record.product.productWidth??0,
      productHeight: record.product.productHeight??0,
      productLength: record.product.productLength??0,
      productWeight: record.product.productWeight??0,
    });

    console.log(resp, '....');
    setEditId(null);
  }

  const handleProductSpecificationChange = (orderProduct, prop, value) => {
    const op = orderProducts.find(o => o.id === orderProduct.id);
    op.product[prop] = value;
    const idx = orderProducts.findIndex(o => o.id === orderProduct.id);

    setOrderProducts([
      ...orderProducts.slice(0, idx),
      op,
      ...orderProducts.slice(idx + 1),
    ])
  }

  const handleUpdateOrderProductWarehouseReceiptCategory = async (record, receiptCategoryId) => {
    const resp = await purchaseOrderApi.updatePsoOrderProductReceiptCategory(record.id, {
      receiptCategoryId,
    });

    console.log(resp);

    fetchPruchaseOrder();
  }

  const handleAutoAssignWarehouseReceipts = async () => {
    const resp = await purchaseOrderApi.autoAssignWarehouseReceipts(53);

    console.log(resp);
  }

  const getPsoOrderProductWarehouseReceiptId = (record) => {
    const receipt = warehouseReceipts.find(receipt => {
      return receipt.items.find(item => item.psoOrderProductId === record.id) !== undefined;
    });

    return receipt && receipt.id;
  }

  const columns = [
    {
      title: '商品',
      dataIndex: 'name',
      width: '18%',
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={record.productImage} alt={record.productName} style={{ height: 56, width: 56, border: '1px solid #eee', marginRight: 8 }} />
          <span
            style={{
              display: 'block',
              width: '200px', // 可根据实际宽度调整
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              lineHeight: '1.2em',
              maxHeight: '2.4em', // 2行文字的高度（1.2em * 2）
            }}
          >{record.productName}</span>
        </div>
      ),
    },
    {
      title: 'SKU',
      dataIndex: 'skuInfo',
      width: '12%',
    },
    {
      title: '数量',
      dataIndex: 'quantity',
      width: '8%',
    },
    {
      title: '规格(长x宽x高x重)',
      dataIndex: 'ckg',
      width: '18%',
      render: (text, record) => (
        <Space direction="horizontal" size="small">
          {editId === record.id ? (
            <>
          <InputNumber
            placeholder="长"
            value={record.product?.productLength??0}
            onChange={(e) => handleProductSpecificationChange(record, 'productLength', e)}
            disabled={autoAssigned}
            precision={2}
            defaultValue={0}
            min={0}
            style={{ width: '80px' }}
          />
          <InputNumber
            placeholder="宽"
            value={record.product?.productWidth??0}
            onChange={(e) => handleProductSpecificationChange(record, 'productWidth', e)}
            disabled={autoAssigned}
            precision={2}
            defaultValue={0}
            min={0}
            style={{ width: '80px' }}
          />
          <InputNumber
            placeholder="高"
            value={record.product?.productHeight??0}
            onChange={(e) => handleProductSpecificationChange(record, 'productHeight', e)}
            disabled={autoAssigned}
            precision={2}
            defaultValue={0}
            min={0}
            style={{ width: '80px' }}
          />
          <InputNumber
            placeholder="重量"
            value={record.product?.productWeight??0}
            onChange={(e) => handleProductSpecificationChange(record, 'productWeight', e)}
            disabled={autoAssigned}
            precision={2}
            defaultValue={0}
            min={0}
            style={{ width: '80px' }}
          />
              <CheckCircleOutlined
                onClick={() => handleUpdateProductSpecification(record)}
              />
            </>
          ) : (
            <>
              <span>{`${(record.product?.productLength ?? 0).toFixed(2)} x ${(record.product?.productWidth ?? 0).toFixed(2)} x ${(record.product?.productHeight ?? 0).toFixed(2)} x ${(record.product?.productWeight ?? 0).toFixed(2)}`}</span>
              <EditOutlined
                onClick={() => setEditId(record.id)}
                style={{ marginLeft: 8, cursor: 'pointer' }}
              />
            </>
          )}
        </Space>)
    },
    {
      title: '分仓类别',
      dataIndex: 'category',
      width: '16%',
      render: (text, record) => (
        <Select
          value={record.receiptCategoryId}
          onChange={(value) => handleUpdateOrderProductWarehouseReceiptCategory(record, value)}
          disabled={autoAssigned}
          style={{ width: '100%' }}
        >
          {warehouseReceiptCategories.map((category) => (
            <Option key={category.value} value={category.value}>
              {category.text}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: '仓号',
      dataIndex: 'warehouse',
      width: '8%',
      render: (text, record) => (
        <Select
          value={getPsoOrderProductWarehouseReceiptId(record)}
          onChange={(value) => handleWarehouseChange(record.id, value)}
          disabled={autoAssigned}
          style={{ width: '160px'}}
        >
          {warehouseReceipts.map((warehouseReceipt) => (
            <Option key={warehouseReceipt.id} value={warehouseReceipt.id}>
              {warehouseReceipt.receiptNo}
            </Option>
          ))}
        </Select>
      ),
    },
  ];

  return (
    <div className='inner-content relative px-[16px]' style={{ paddingTop: '20px', paddingBottom: '32px'  }}>
      {/* 采购单信息部分 */}
      <Row gutter={16} style={{ marginBottom: '20px' }}>
        <Col span={24}>
          <Space direction="vertical" size="small">
            <Text strong>采购单号：{orderInfo.orderNumber}</Text>
            <Text strong>状态：{orderInfo.status}</Text>
            <Text strong>创建时间：{orderInfo.createDate}</Text>
          </Space>
        </Col>
      </Row>

      {/* 控制按钮部分 */}
      <Row gutter={16} style={{ marginBottom: '20px' }}>
        <Col span={24}>
          <Space>
            <Button type="primary" onClick={handleAutoAssignWarehouseReceipts} disabled={autoAssigned}>
              自动分仓
            </Button>
            <Button onClick={handleDeleteSelected} disabled={selectedProducts.length === 0}>
              删除选择的商品
            </Button>
          </Space>
        </Col>
      </Row>

      {/* 商品列表展示 */}
      <Table
        rowKey="id"
        columns={columns}
        dataSource={orderProducts}
        pagination={false}
        size="middle"
      />

      {/* 确认分仓按钮 */}
      <Row gutter={16} style={{ marginTop: '20px' }}>
        <Col span={24}>
          <Button type="primary" onClick={handleConfirm} disabled={selectedProducts.length === 0}>
            确认分仓
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default WarehouseManagement;
