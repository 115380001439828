import React, { useState } from "react";
import { Modal, Form, InputNumber, Table, Button, Select, Space } from "antd";
import FormItem from "antd/es/form/FormItem";

const Option = Select.Option;

const CreateWarehouseReceiptOverlay = ({ visible, onCancel, onSubmit, psoOrderProducts = [], warehouseReceiptCategories = [] }) => {
  const [form] = Form.useForm();

  // 处理表单输入
  const handleInputChange = (key, field, value) => {
    const newData = [...form.getFieldValue("data")];
    newData[key] = { ...newData[key], [field]: value };
    form.setFieldsValue({ data: newData });
  };

  // 提交表单
  const handleOk = () => {
    form.validateFields()
      .then((values) => {
        // 合并表单数据到 psoOrderProducts
        const mergedData = psoOrderProducts.map((item, index) => ({
          ...item,
          boxCount: values.data?.[index]?.boxCount || 0,
          boxPackQty: values.data?.[index]?.boxPackQty || 0,
          boxLength: values.data?.[index]?.boxLength || 0,
          boxWidth: values.data?.[index]?.boxWidth || 0,
          boxHeight: values.data?.[index]?.boxHeight || 0,
          boxWeight: values.data?.[index]?.boxWeight || 0,
        }));

        onSubmit(mergedData, values.warehouseReceiptCategoryId); // 提交完整数据
        form.resetFields();
      })
      .catch((info) => {
        console.log("验证失败:", info);
      });
  };


  // 初始化表单默认值
  React.useEffect(() => {
    form.setFieldsValue({
      data: psoOrderProducts.map(item => ({
        boxCount: item.boxCount || undefined,
        boxPackQty: item.boxPackQty || undefined,
        boxLength: item.boxLength || undefined,
        boxWidth: item.boxWidth || undefined,
        boxHeight: item.boxHeight || undefined,
        boxWeight: item.boxWeight || undefined,
      })),
    });
  }, [psoOrderProducts, form]);

  // 表格列配置
  const columns = [
    {
      title: "货物名称",
      dataIndex: "productName",
      key: "productName",
      width: '30%',
      render: (text) => text,
    },
    {
      title: "SKU",
      dataIndex: "skuInfo",
      key: "skuInfo",
      render: (text) => text,
    },
    {
      title: "数量",
      dataIndex: "quantity",
      key: "quantity",
      render: (text) => text,
    },
    {
      title: "总箱数",
      dataIndex: "boxCount",
      key: "boxCount",
      render: (_, record, index) => (
        <Form.Item name={["data", index, "boxCount"]} rules={[{ required: true, message: "请输入箱数" }]}>
          <InputNumber min={1} onChange={(value) => handleInputChange(index, "boxCount", value)} />
        </Form.Item>
      ),
    },
    {
      title: "每箱数量",
      dataIndex: "boxPackQty",
      key: "boxPackQty",
      render: (_, record, index) => (
        <Form.Item name={["data", index, "boxPackQty"]} rules={[{ required: true, message: "请输入装箱数量" }]}>
          <InputNumber min={1} onChange={(value) => handleInputChange(index, "boxPackQty", value)} />
        </Form.Item>
      ),
    },
    {
      title: "体积 (长 x 宽 x 高 cm)",
      key: "dimensions",
      render: (_, record, index) => (
        <div style={{ display: "flex", gap: "8px" }}>
          <Form.Item name={["data", index, "boxLength"]} rules={[{ required: true, message: "请输入长" }]}>
            <InputNumber min={1} placeholder="长" onChange={(value) => handleInputChange(index, "boxLength", value)} />
          </Form.Item>
          <Form.Item name={["data", index, "boxWidth"]} rules={[{ required: true, message: "请输入宽" }]}>
            <InputNumber min={1} placeholder="宽" onChange={(value) => handleInputChange(index, "boxWidth", value)} />
          </Form.Item>
          <Form.Item name={["data", index, "boxHeight"]} rules={[{ required: true, message: "请输入高" }]}>
            <InputNumber min={1} placeholder="高" onChange={(value) => handleInputChange(index, "boxHeight", value)} />
          </Form.Item>
        </div>
      ),
    },
    {
      title: "每箱重量 (kg)",
      dataIndex: "boxWeight",
      key: "boxWeight",
      render: (_, record, index) => (
        <Form.Item name={["data", index, "boxWeight"]} rules={[{ required: true, message: "请输入重量" }]}>
          <InputNumber min={0.1} step={0.1} onChange={(value) => handleInputChange(index, "boxWeight", value)} />
        </Form.Item>
      ),
    },
  ];

  return (
    <Modal
      title="创建入仓面单"
      open={visible}
      onCancel={onCancel}
      width={1400}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          取消
        </Button>,
        <Button key="submit" type="primary" onClick={handleOk}>
          提交
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Table dataSource={psoOrderProducts} columns={columns} rowKey="key" pagination={false} bordered />
        <div style={{ display: 'flex', alignItems: 'center'}}>
          <span>仓单分类：</span>
          <FormItem name={"warehouseReceiptCategoryId"} rules={[{ required: true, message: "请选择仓单分类" }]} style={{ marginBottom: 0 }}>
            <Select style={{ width: 280, marginTop: 16, marginBottom: 16 }}>
              {
                warehouseReceiptCategories.map(warehouseReceiptCategory => (<Option value={warehouseReceiptCategory.value}>{warehouseReceiptCategory.text}</Option>))
              }
            </Select>
          </FormItem>
        </div>
      </Form>
    </Modal>
  );
};

export default CreateWarehouseReceiptOverlay;
