import React from "react";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";
const { Text } = Typography;

const SupplierCrossOrderProductLogisticsStatus = ({ status }) => {
  const { t } = useTranslation();
  const getStatusText = (status) => {
    switch (status) {
      case 1:
        return t('status.supplierCrossOrderProductLogisticsStatus.NO_SHIPPED');
      case 2:
        return t('status.supplierCrossOrderProductLogisticsStatus.SHIPPED');
      case 3:
        return t('status.supplierCrossOrderProductLogisticsStatus.RECEIVED');
      case 4:
        return t('status.supplierCrossOrderProductLogisticsStatus.RETURNED');
      case 5:
        return t('status.supplierCrossOrderProductLogisticsStatus.PARTIAL_SHIPPED');
      case 8:
        return t('status.supplierCrossOrderProductLogisticsStatus.NO_DATA');
      default:
        return t('status.supplierCrossOrderProductLogisticsStatus.UNKNOW');
    }
  };

  return <Text>{getStatusText(status)}</Text>;
};


export default SupplierCrossOrderProductLogisticsStatus;