import React, { useState, useEffect } from 'react';
import Dashboard from './Dashboard';
import { Card, Row, Col, Form, Input, Select, Button, Table, Checkbox, TreeSelect, Radio, Tooltip, message, Popconfirm, Modal } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import productApi from '../servapi/product';
import shopApi from '../servapi/shop';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import listingProductApi from '../servapi/listing_product';
import './ShopProductList.less';
import PushStatus from './component/status/PushStatus';

const RadioGroup = Radio.Group;

const { Option } = Select;

function formatDate(dateString) {
  const date = new Date(dateString);

  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

const RadioOptions = ({ onPushStore, onDelete, onGetData, selectedRowKeys = [] }) => {
  const { t } = useTranslation();
  return (

    <RadioGroup className="ant-radio-group ant-radio-group-outline">
      <Radio.Button value="a" disabled>
        <span style={{ color: 'red', marginRight: '5px' }}>
          [{selectedRowKeys.length}]
        </span>
        <span>{t("productList.btns.selectedProduct")}</span>
      </Radio.Button>
      <Radio.Button value="push" onClick={onPushStore}>
        <span>{t("productList.btns.pushStore")}</span>
      </Radio.Button>
      <Radio.Button value="update" onClick={onGetData}>
        <span>{t("productList.btns.refreshData")}</span>
      </Radio.Button>
      <Radio.Button value="delete" onClick={onDelete}>
        <span>{t("productList.btns.deleteSeletedProduct")}</span>
      </Radio.Button>
    </RadioGroup>
  );
};

function transformData(json) {
  return {
    key: json.id.toString(),
    name: json.productName,
    cost: json.lowestPrice ? json.lowestPrice.toString() : 'N/A',
    source: json.platform,
    productUrl: json.productUrl,
    spuCode: json.spuCode,
    price: json.highestPrice,
    platform: json.platform || 'N/A',
    pushStatus: json.pushState ? 'Pushed' : 'Not Pushed',
    inventoryStatus: json.inventoryState ? 'In Stock' : 'Out of Stock',
    updateTime: json.updateTime || 'N/A',
  };
}

const EditShopProductCategoriesForm = ({ visible, onClose, shopProduct, treeData = [], onSave }) => {
  const { t, i18n } = useTranslation();

  const handleUpadte = async (values) => {
    onSave(shopProduct.id, values.shopCategoryIds.map(shopCategoryId => shopCategoryId.value));
  }

  return (
    <Modal
      title={'Edit Shop Product'}
      visible={visible}
      onCancel={onClose}
      centered
      footer={null}
      width={520}
    >
      <Form onFinish={handleUpadte} initialValues={{
        productName: shopProduct.productName,
        shopCategoryIds: shopProduct.shopProductCategories.map(shopCategory => shopCategory.id)
      }}>
        <Form.Item name="productName" label={t("productList.table.name")} rules={[{ required: false }]}>
          <Input disabled />
        </Form.Item>
        <Form.Item name="shopCategoryIds" label={t("productList.table.shopCategory")} rules={[{ required: false }]}>
          <TreeSelect
            treeData={treeData}
            showCheckedStrategy={TreeSelect.SHOW_PARENT}
            treeCheckable
            treeCheckStrictly
            value={shopProduct.shopProductCategories.map(shopCategory => shopCategory.id)}
            // onChange={handleCategoryChange} // 使用自定义的 check 处理逻辑
            style={{ width: '100%' }}
            filterTreeNode={(inputValue, treeNode) => {
              if (treeNode && treeNode.title) {
                return treeNode.title.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
              }

              return false;
            }
            }
          />
        </Form.Item>
        <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
          <Button type="primary" htmlType="submit">
          {t("common.confirm.ok")}
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

const ProductsTable = ({ currentShop = {}, searchParams = {}, treeData = [] }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { t, i18n } = useTranslation();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [editShopProduct, setEditShopProduct] = useState();


  React.useEffect(() => {
    if (!currentShop.id) {
      return;
    }

    fetchShopProducts(currentShop.id, { pagination });
  }, [currentShop, pagination.current, pagination.pageSize, i18n.language]);

  React.useEffect(() => {
    if (!currentShop.id) {
      return;
    }

    fetchShopProducts(currentShop.id, {
      pagination: {
        ...pagination,
        current: 1,
      }
    })
  }, [searchParams])

  const fetchShopProducts = async (id, { pagination }) => {
    setLoading(true);
    const pageData = await shopApi.getShopProducts(id, {
      ...searchParams,
      page: pagination.current - 1,
      size: pagination.pageSize,
    })

    setData(pageData.shopProducts || []);
    setPagination({
      ...pagination,
      current: pageData.number + 1,
      total: pageData.recordsTotal,
    });
    setLoading(false);
  }

  const onSelectChange = (record) => {
    const key = record.id;
    const selectedIndex = selectedRowKeys.indexOf(key);
    let newSelectedRowKeys = [...selectedRowKeys];

    if (selectedIndex === -1) {
      newSelectedRowKeys.push(key);
    } else {
      newSelectedRowKeys.splice(selectedIndex, 1);
    }

    setSelectedRowKeys(newSelectedRowKeys);
  };

  const onToggleAllRows = (checked) => {
    const newSelectedRowKeys = checked ? data.map((record) => record.id) : [];
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handlePushStore = async () => {
    if (selectedRowKeys.length <= 0) {
      return;
    }

    const hide = message.loading(t("shopProducts.message.beingPushProcuct"), 0);
    const resp = await shopApi.pushProductsToShop(currentShop.id, {
      shopProductIds: selectedRowKeys
    });
    console.log(resp);
    hide();
    message.success(t("shopProducts.message.pushedProduct"))
    fetchShopProducts(currentShop.id, { pagination })
  }


  const handleDelete = async (record) => {
    const resp = await shopApi.deleteShopProduct(currentShop.id, record.id);
    console.log(resp);

    fetchShopProducts(currentShop.id, { pagination })
  }

  const handleUpdateCategories = async (shopProductId, shopCategoryIds) => {
    const resp = await shopApi.updateShopProductCategories(currentShop.id, shopProductId, { shopCategoryIds });
    console.log(resp);

    setEditShopProduct(null);
    fetchShopProducts(currentShop.id, { pagination })
  }

  const quoteType = {
    "1": t("productList.quoteType.skuPrice"),
    "2": t("productList.quoteType.rangePrice"),
  }

  // const data = pageData?.content || [];
  const columns = [
    {
      title: (
        <Checkbox
          indeterminate={selectedRowKeys.length > 0 && selectedRowKeys.length < data.length}
          checked={selectedRowKeys.length === data.length}
          onChange={(e) => onToggleAllRows(e.target.checked)}
        />
      ),
      key: 'selection',
      dataIndex: 'selection',
      render: (_, record) => (
        <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
          <Checkbox
            onChange={() => onSelectChange(record)}
            checked={selectedRowKeys.includes(record.id)}
          />
        </div>

      ),
      width: '5%',
    },
    {
      title: t("productList.table.name"),
      dataIndex: 'productName',
      key: 'productName',
      width: '25%',
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={record.productImage}
            alt={text}
            style={{ width: '50px', height: '50px', marginRight: '10px' }}
          />
          <Link to={`/product/detail/${record.outProductId}`} target="_blank">
            <span style={{ color: '#000' }} >{text}</span>
          </Link>

        </div>
      ),
    },
    {
      title: t("productList.table.shopLink"),
      dataIndex: 'wpProductLink',
      key: 'wpProductLink',
      width: '8%',
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Link to={`${record.wpProductLink}`} target="_blank" style={{ display: 'flex', alignItems: 'center'}}>
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAMAAAC5zwKfAAAAb1BMVEWbXI////+ZWY2VUoiXVYr7+PumbJvTuM7izt7u4+zn1+Tx5+/bw9fLqsWue6SrdqGocJ39+/2fYZPz7PLStMyxgKihZpb38fa+lbbOr8nGor/fytqTTob59fi7kLO1havq3OfXvdLCmrrs3+m4i7CnngS0AAACy0lEQVRYw+2U2a7qMAxFE9stkNK5hQ4chgv//43XdoOOhEoQiJcjdT+0TessdjxgFi1atGjRor8ieFthHFbxm6oogKR4lURvqt7m9JSX1/YD/dyeEKFK7Ef6iedPjf/m412WuTCxx3ng1tqmaDcP0VGBGEdBoJt3SGubAB0efW7RQPwjT/v16H7Jkduufc43Bp4Ar2AoewCeaEpGlO6IIG7uv1MAUdXVYeB+B+JGlbSFk1sFABvmnQ6yC/wJeiRZYVEHgTbjsI13dji0euLJdIeynzfiRd+CAWHiLQw88vZReWsysNtb+w8Npro0VHQx8DXSRoGqa5lDLghMeXsvvFK8ghPP8qFs+ZbtZau87ZHvTt/iEARuOaLzteXzNJrVqrZn8H1/5ICVzfWq4XQKAu9ljnRA6SYtBnmpzrUaK8lAwi+LSCpmAPIQUJIDOy7cBXUY41JqctTeAeedYy+XQVb17hXQSq4cTwd7kIyfG61JmYNUyDu89KhjpQ6pDQMHrQpjoJB0pYPujdj4NH4dctwgF1mNkvIwUI7Y8X4uyEYylfFFHGtprJJNrb/i8diEgVcGZOnUg3pujk1880z/K9RqiToJlu9JGCjmRJhqU7J8ezMoslsG4EWbvrraTUEvJ0UbzcBESSROG0QQ7LxlHuV+UOJTxVxwr4ANeoOaIgHmMjgtcoBQae2DgJhyGOwr4Bnux/QWKdPHGEFiaKWDefR/PbfoFVBaTmdOlaIClXisiCAfrapMYyDapaUNAafgATVPqrKVhZ1Ur8ezAlQ/bnR7/3ydB2KvX8+E5mzvkBgPJxuW9vec/Nlsn7vf2OTEq7C0M+aJvf1II5h5AaTR+7jyohmcJ2IxNKtZJT6nj5+bISPhPUc+UZXutba7Az6IzEcCjC+l78wvCTAb/fB8S0S3s8z3F4UwXMB8U4CV+bLALFq0aNGiRX9U/wF+Gie0vd1siQAAAABJRU5ErkJggg=="
              alt={text}
              style={{ width: '20px', height: '20px', marginRight: '4px' }}
            />
            <span>{record.wpProductId}</span>
          </Link>
        </div>
      ),
    },
    {
      title: t("productList.table.shopCategory"),
      dataIndex: 'shopCategory',
      key: 'shopCategory',
      width: '15%',
      render: (text, record) => (
        <TreeSelect
          treeData={treeData}
          showCheckedStrategy={TreeSelect.SHOW_PARENT}
          treeCheckable
          treeCheckStrictly
          value={record.shopProductCategories.map(shopCategory => shopCategory.id)}
          // onChange={handleCategoryChange} // 使用自定义的 check 处理逻辑
          style={{ width: '100%' }}
          disabled
          filterTreeNode={(inputValue, treeNode) => {
            if (treeNode && treeNode.title) {
              return treeNode.title.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
            }

            return false;
          }
          }
        />
      ),
    },
    {
      title: t("productList.table.price"),
      dataIndex: 'price',
      key: 'price',
      width: '8%',
      render: (_, record) => {
        let price = record.price;

        if (record.quoteType === 2) {
          const rangePrices = JSON.parse(record.rangePrices || '[]');

          if (rangePrices.length > 0) {
            const start = rangePrices[0];
            const end = rangePrices[rangePrices.length - 1];
            price = `${start.price} - ${end.price}`;
          }
        }
        return (
          <span>{t("common.currency.symbol")}{price}</span>
        )
      }
    },
    {
      title: t("productList.table.quoteType"),
      dataIndex: 'quoteType',
      key: 'quoteType',
      width: '8%',
      render: (_, record) => {
        return (
          <span>{quoteType[`${record.quoteType}`]}</span>
        )
      }
    },
    {
      title: (
        <Tooltip title={t("productList.table.pushStatus")}>
          {t("productList.table.pushStatus")}
          {/* <QuestionCircleOutlined style={{ marginLeft: '8px' }} /> */}
        </Tooltip>
      ),
      dataIndex: 'pushState',
      key: 'pushState',
      width: '8%',
      render: (_, record) => <PushStatus status={record.pushState} />
    },
    {
      title: `${t("productList.table.updateTime")}(UTC)`,
      dataIndex: 'lastPushTime',
      key: 'lastPushTime',
      width: '12%',
      render: (_, record) => <span>{formatDate(record.lastPushTime)}</span>
    },
    {
      title: t("productList.table.action"),
      key: 'action',
      fixed: 'right',
      render: (_, record) => (
        <>
          <a href="javascript:void(0);" style={{ marginRight: 8 }} onClick={() => setEditShopProduct(record)}>Edit</a>
          <Popconfirm
            title={t("shopProducts.confirm.delete")}
            onConfirm={() => handleDelete(record)} // 替换为实际删除逻辑
            okText={t("common.confirm.ok")}
            cancelText={t("common.confirm.cancel")}
          >
            <a>Delete</a>
          </Popconfirm>
        </>
      ),
      width: '10%',
    },
  ];

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const handleDeleteSelected = async () => {
    if (selectedRowKeys.length <= 0) {
      return;
    }

    for (const shopProductId of selectedRowKeys) {
      const resp = await shopApi.deleteShopProduct(currentShop.id, shopProductId);
      console.log(resp);
    }

    setSelectedRowKeys([]);
    fetchShopProducts(currentShop.id, { pagination });
  }

  return (
    <>
      <div className="patch flex-center-vertical">
        <RadioOptions
          onPushStore={handlePushStore}
          selectedRowKeys={selectedRowKeys}
          onGetData={() => fetchShopProducts(currentShop.id, { pagination })}
          onDelete={handleDeleteSelected}
        />
      </div>
      <div className="antd-table-wrapper table w-full">
        <Table
          columns={columns}
          dataSource={data}
          // scroll={{ x: 'max-content' }}
          loading={loading}
          pagination={pagination}
          rowKey="id"
          onChange={handleTableChange}
          className="products-table"
        />
      </div>
      {
        editShopProduct && <EditShopProductCategoriesForm visible={true} shopProduct={editShopProduct} treeData={treeData} onClose={() => setEditShopProduct(null)} onSave={handleUpdateCategories}/>
      }
    </>

  );
}


export default props => {
  const { t } = useTranslation();
  const [currentShop, setCurrentShop] = useState({});
  const [shops, setShops] = useState([]);
  const [searchParams, setSearchParams] = useState({});
  const [productTitle, setProductTitle] = useState();
  const [pushState, setPushState] = useState();
  const [shopCategories, setShopCategories] = useState([]);
  const [treeData, setTreeData] = useState([]);

  const buildTree = (data, parentId = null) => {
    return data
      .filter(category => category.parentId === parentId)
      .map(category => ({
        title: category.description?.name,
        value: category.id,
        key: category.id,
        children: buildTree(data, category.id),
      }));
  };

  React.useEffect(() => {
    shopApi.getAllShopCategories(currentShop.id)
      .then(shopCategories => {
        setTreeData(buildTree(shopCategories))
      })
      .catch(console.log);
  }, [currentShop.id]);



  useEffect(() => {
    shopApi.getShopList()
      .then(shops => {
        setShops(shops);
        setCurrentShop(shops[0]);
      })
      .catch(console.log);
  }, []);

  const handleShopChange = id => {
    const shop = shops.filter(shop => shop.id === id)[0];

    setCurrentShop({
      ...shop,
    })
  }

  const handleSearch = () => {
    setSearchParams({
      productTitle,
      pushState,
      shopCategoryIds: shopCategories.map(shopCategory => shopCategory.value),
    })
  }

  const handleClear = () => {
    setProductTitle(null);
    setPushState(null);
    setShopCategories([]);
    setSearchParams({});
  }


  return (
    <>
      <div className="page_head">
        <div id="page_head_content" style={{ height: '72px' }}>
          <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
            <Col span={12}>
              <div className="flex-center-vertical">
                <h3 className="ant-typography ant-typography-ellipsis ant-typography-single-line ant-typography-ellipsis-single-line text-24" style={{ margin: 0, lineHeight: '72px', minHeight: '72px', padding: '0px 8px', flexGrow: 1 }}>
                  {t("shopProducts.title")}
                </h3>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className='inner-content relative px-[16px]' style={{ paddingTop: '82px', paddingBottom: '32px' }}>
        <div className="saved-products">
          <Card>
            <div className="ant-card-body">
              <Form className="ant-legacy-form ant-legacy-form-inline block-item">
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item label={t("productList.search.shop")} className="ant-legacy-form-item-label-left">
                      <Select placeholder={t("productList.search.all")} id="platform" value={currentShop && currentShop.id} onChange={handleShopChange}>
                        {
                          shops.map(shop => (<Option value={shop.id}>{shop.shopName} - {shop.shopUrl}</Option>))
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label={t("productList.search.productName")} className="ant-legacy-form-item-label-left">
                      <Input placeholder={t("productList.search.productPlaceHolder")} id="keyword" value={productTitle} onChange={e => setProductTitle(e.target.value)} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label={t("productList.search.pushStatus")} className="ant-legacy-form-item-label-left">
                      <Select placeholder={t("productList.search.all")} id="pushState" onChange={e => setPushState(e)} value={pushState}>
                        <Option value="PENDING">PENDING</Option>
                        <Option value="SUCCEED">SUCCEED</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label={t("productList.search.shopCategories")} className="ant-legacy-form-item-label-left">
                      <TreeSelect
                        treeData={treeData}
                        showCheckedStrategy={TreeSelect.SHOW_PARENT}
                        treeCheckable
                        treeCheckStrictly
                        value={shopCategories}
                        onChange={value => setShopCategories(value)} // 使用自定义的 check 处理逻辑
                        style={{ width: '100%' }}

                        filterTreeNode={(inputValue, treeNode) => {
                          if (treeNode && treeNode.title) {
                            return treeNode.title.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                          }

                          return false;
                        }
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={16} style={{ display: 'flex', textAlign: 'right', justifyContent: 'flex-end' }}>
                    <Form.Item>
                      <Button type="primary" htmlType="button" style={{ marginRight: 8 }} onClick={handleSearch}>{t("productList.search.searchBtn")}</Button>
                    </Form.Item>
                    <Form.Item>
                      <Button htmlType="button" onClick={handleClear}>{t("productList.search.clearBtn")}</Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </Card>
          <Card className="products-table mt-[16px]">
            <ProductsTable currentShop={currentShop} searchParams={searchParams} treeData={treeData} />
          </Card>
        </div>
      </div>
    </>
  )
}