import React from "react";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";
const { Text } = Typography;

const PurchaseOrderStatus = ({ status }) => {
  const { t } = useTranslation();
  const getStatusText = (status) => {
    switch (status) {
      case 'NEW':
        return t('status.purchaseOrderStatus.NEW');
      case 'PENDING_PAY':
        return t('status.purchaseOrderStatus.PENDING_PAY');
      case 'PARTIAL_PAID':
        return t('status.purchaseOrderStatus.PARTIAL_PAID');
      case 'PAID':
        return t('status.purchaseOrderStatus.PAID');
      case 'PARTIAL_SHIPPED':
        return t('status.purchaseOrderStatus.PARTIAL_SHIPPED');
      case 'SHIPPED':
        return t('status.purchaseOrderStatus.SHIPPED');
      case 'IN_TRANSIT':
        return t('status.purchaseOrderStatus.IN_TRANSIT');
      case 'COMPLETED':
        return t('status.purchaseOrderStatus.COMPLETED');
      case 'CANCELLED':
        return t('status.purchaseOrderStatus.CANCELLED');
      case 'REFUNDED':
        return t('status.purchaseOrderStatus.REFUNDED');
      case 'RETURNED':
        return t('status.purchaseOrderStatus.RETURNED');
      case 'EXCEPTION':
        return t('status.purchaseOrderStatus.EXCEPTION');
      default:
        return t('status.purchaseOrderStatus.UNKNOWN');
    }
  };

  return <Text>{getStatusText(status)}</Text>;
};


export default PurchaseOrderStatus;