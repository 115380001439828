import React, { useState, useEffect } from 'react';
import { Typography, Card, Button, Descriptions, Divider, DatePicker, Table, Tag, Pagination, Timeline, Row, Col, Select, Input, Popover, message, Tabs, Space } from 'antd';
import Dashboard from './Dashboard';
import './PurchaseOrderList.less';
import saleOrderApi from '../servapi/sale_order';
import shopApi from '../servapi/shop';
import { FileTextOutlined, ClockCircleOutlined, CheckCircleOutlined, UserOutlined, FormOutlined, UpOutlined, DownOutlined, AccountBookOutlined } from '@ant-design/icons';
import purchaseOrderApi from '../servapi/purchase_order';
import PurchaseOrderStatus from './component/status/PurchaseOrderStatus';
import PurchaseSupplierOrderPayStatus from './component/status/PurchaseSupplierOrderPayStatus';
import PurchaseSupplierOrderStatus from './component/status/PurchaseSupplierOrderStatus';
import { useTranslation } from 'react-i18next';
import SupplierCrossOrderProductLogisticsStatus from './component/status/SupplierCrossOrderProductLogisticsStatus';
import LogisticsTrackingPopup from './component/LogisticsTraceOverlay';
import { Link, useNavigate } from "react-router-dom";
import { formatDateTime } from '../util/date';
import { preventAndStop } from '../util/common';
import logisticsApi from '../servapi/logistics';
import IntlLogisticsTrackingPopup from './component/IntlLogisticsTraceOverlay';
import PurchaseSupplierOrderProductStatus from './component/status/PurchaseSupplierOrderProductStatus';
import moment from 'moment';

const { Text } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

const SearchOrders = ({ onSearch, onReset }) => {
    const { t } = useTranslation();
  const [orderNo, setOrderNo] = useState('');
  const [recipientName, setRecipientName] = useState('');
  const [status, setStatus] = useState(null);
  const [dateRange, setDateRange] = useState([]);

  const handleSearch = () => {
    const searchCriteria = {
      orderNo,
      status,
      startDate: dateRange && dateRange[0] ? dateRange[0].format('YYYY-MM-DD') + ' 00:00:00' : null,
      endDate: dateRange && dateRange[1] ? dateRange[1].format('YYYY-MM-DD') + ' 23:59:59' : null,
    };
    onSearch(searchCriteria);
  }

    // 重置表单
    const resetFilters = () => {
      setOrderNo('');
      setStatus(null);
      setDateRange([]);
  
      onReset();
    };

    const ranges = {
      [t('shopOrders.search.timeRanges.recent3day')]: [moment().subtract(3, 'days'), moment()],
      [t('shopOrders.search.timeRanges.recentWeek')]: [moment().subtract(7, 'days'), moment()],
      [t('shopOrders.search.timeRanges.recentMonth')]: [moment().subtract(1, 'months'), moment()],
    };
    
    const statuses = {
      "NEW": t("status.purchaseOrderStatus.NEW"),
      "PENDING_PAY": t("status.purchaseOrderStatus.PENDING_PAY"),
      "PARTIAL_PAID": t("status.purchaseOrderStatus.PARTIAL_PAID"),
      "PAID": t("status.purchaseOrderStatus.PAID"),
      "PARTIAL_SHIPPED": t("status.purchaseOrderStatus.PARTIAL_SHIPPED"),
      "SHIPPED": t("status.purchaseOrderStatus.SHIPPED"),
      "IN_TRANSIT": t("status.purchaseOrderStatus.IN_TRANSIT"),
      "COMPLETED": t("status.purchaseOrderStatus.COMPLETED"),
      "REFUNDED": t("status.purchaseOrderStatus.REFUNDED"),
      "RETURNED": t("status.purchaseOrderStatus.RETURNED"),
      "EXCEPTION": t("status.purchaseOrderStatus.EXCEPTION"),
    }
  return (
    <Card title="搜索订单" style={{ margin: '20px' }}>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div style={{ marginRight: '16px', marginBottom: '8px' }}>
          <div style={{ marginBottom: '6px', color: 'rgba(0, 0, 0, 0.85)' }}>采购单号</div>
          <Input 
            value={orderNo}
            onChange={e => setOrderNo(e.target.value.trim())}
            placeholder="采购单号" 
            style={{ width: '260px' }} 
            allowClear/>
        </div>
        <div style={{ marginRight: '16px', marginBottom: '8px' }}>
          <div style={{ marginBottom: '6px', color: 'rgba(0, 0, 0, 0.85)' }}>状态</div>
          <Select
            value={status}
            onChange={setStatus}
            placeholder={t("shopOrders.search.all")}
            style={{ width: '180px' }}
            allowClear
          >
            {
              Object.keys(statuses).map(key => <Option value={key}>{statuses[key]}</Option>)
            }
          </Select>
        </div>

        <div style={{ marginRight: '16px', marginBottom: '8px' }}>
          <div style={{ marginBottom: '6px', color: 'rgba(0, 0, 0, 0.85)' }}>时间区间</div>
          <RangePicker
            value={dateRange}
            onChange={setDateRange}
            ranges={ranges}
            style={{ width: '256px' }}
          />
        </div>

        <div style={{ marginRight: '16px', marginBottom: '8px', display: 'flex', alignItems: 'flex-end' }}>
          <Button type="primary" style={{ marginRight: 8 }} onClick={handleSearch}>搜索</Button>
          <Button onClick={resetFilters}>清除</Button>
        </div>
      </div>
    </Card>
  );
};

const LogisticsTracking = ({ id }) => {
  const [data, setData] = useState();

  useEffect(() => {
    if (data) {
      return;
    }
    purchaseOrderApi.getPurchaseOrderProductLogisticsTraces(id)
      .then(resp => {
        if (resp && resp.length > 0) {
          setData(resp[0])
        }
      })
      .catch(console.log);
  }, [])

  const sortedLogisticsSteps = data?.logisticsSteps?.sort((a, b) => new Date(b.acceptTime) - new Date(a.acceptTime));

  return (
    <div style={{ maxWidth: '300px', maxHeight: '400px', overflowY: 'auto', width: 300 }}>
      {/* 物流信息详情 */}
      <div style={{ marginBottom: '16px' }}>
        <Text strong>物流编号: </Text><Text>{data?.logisticsId}</Text><br />
        <Text strong>物流单编号: </Text><Text>{data?.logisticsBillNo}</Text><br />
        <Text strong>订单编号: </Text><Text>{data?.crossOrderId}</Text>
      </div>

      {/* 物流追踪时间线 */}
      <Timeline>
        {sortedLogisticsSteps?.map((step, index) => (
          <Timeline.Item key={index}>
            <div>{step.acceptTime}</div>
            <div>{step.remark}</div>
          </Timeline.Item>
        ))}
      </Timeline>
    </div>
  )
}

const IntlLogisticsTracking = ({ id }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (data.length > 0) {
      return;
    }

    purchaseOrderApi.getPurchaseOrderProductIntlLogisticsTraces(id)
      .then(resp => {
        if (resp && resp.length > 0) {
          setData(resp)
        }
      })
      .catch(console.log);
  }, []);

  const sortedLogisticsSteps = data.sort((a, b) => new Date(b.dates) - new Date(a.dates));
  return (
    <div style={{ maxWidth: '500px', maxHeight: '600px', overflowY: 'auto', width: 300  }}>

      {/* 物流追踪时间线 */}
      <Timeline style={{ paddingTop: 8}}>
        {sortedLogisticsSteps?.map((step, index) => (
          <Timeline.Item key={index}>
            <div>{step.dates}</div>
            <div>{step.content}</div>
          </Timeline.Item>
        ))}
      </Timeline>
    </div>
  )
}


const SupplierOrderCard = ({ supplierOrder = {}, onReCreateSupplierOrder }) => {
  const { t } = useTranslation();
  const [showAll, setShowAll] = useState(false);

  // Calculate the initial remaining time
  const calculateRemainingTime = () => {
    const createdTime = new Date(supplierOrder.createdTime).getTime(); // Convert createdTime to timestamp
    const now = new Date().getTime(); // Current timestamp
    const elapsedTime = (now - createdTime) / 1000; // Elapsed time in seconds
    const remainingTime = supplierOrder.payTimeout - elapsedTime; // Remaining time in seconds
    return Math.max(remainingTime, 0); // Ensure it's not negative
  };

  const [remainingTime, setRemainingTime] = useState(calculateRemainingTime());

  // Countdown logic
  useEffect(() => {
    if (remainingTime > 0) {
      const intervalId = setInterval(() => {
        setRemainingTime((time) => time - 1);
      }, 1000); // Decrease by 1 every second

      return () => clearInterval(intervalId); // Clear interval on component unmount
    }
  }, [remainingTime]);


  useEffect(() => {
    setRemainingTime(calculateRemainingTime())
  }, [supplierOrder.payTimeout])

  const formatTime = (time) => {
    const days = Math.floor(time / (3600 * 24));
    const hours = Math.floor((time % (3600 * 24)) / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    return `${days}天${hours}小时${minutes}分`;
  };

  const getOrderProductLogisticsStatus = record => {
    return record.status;
    // const { crossOrders = [] } = supplierOrder;
    // const crossOrderProducts = crossOrders.map(crossOrder => crossOrder.products).reduce((sum, products) => [...sum, ...products], []);

    // const filtered = crossOrderProducts.filter(crossOrderProduct => `${crossOrderProduct.skuId}` === record.sku);

    // if (filtered.length > 0) {
    //   return filtered[0].logisticsStatus;
    // }
  }

  const columns = [
    {
      title: 'Product',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={record.productImage} alt={record.productName} style={{ height: 56, width: 56, border: '1px solid #eee', marginRight: 8 }} />
          <span>{record.productName}</span>
        </div>
      ),
      width: '30%'
    },
    {
      title: 'Sku',
      dataIndex: 'skuInfo',
      key: 'skuInfo',
      width: '15%'
    },
    {
      title: 'Price',
      dataIndex: 'unitPrice',
      key: 'unitPrice',
      width: '10%',
      render: (_, record) => (<span>¥{record.unitPrice}</span>)
    },
    {
      title: 'Quantity',
      key: 'quantity',
      render: (text, record) => (
        <>
          <div><strong>{record.quantity}</strong></div>
        </>
      ),
      width: '10%'
    },
    {
      title: 'Status',
      key: 'logistics',
      render: (text, record) => (
        <>
          <div><Tag><PurchaseSupplierOrderProductStatus status={getOrderProductLogisticsStatus(record)} /></Tag></div>
        </>
      ),
      width: '15%'
    },
    {
      title: 'Detail',
      key: 'detail',
      render: (text, record) => (
        <>
          <div style={{ color: '#999', cursor: 'pointer' }}>
            <LogisticsQueryPopover record={record}  />
          </div>
        </>
      ),
      width: '20%'
    },
  ];

  const gotoPay = supplierOrder => {
    window.open(supplierOrder.payUrl);
  }

  const reCreatePurchaseSupplierOrder = async (supplierOrder) => {
    onReCreateSupplierOrder(supplierOrder);
  }

  const dataSource = showAll ? supplierOrder.orderProducts : supplierOrder.orderProducts.slice(0, 3);

  return (
    <Card bodyStyle={{ padding: 0 }} style={{ borderRadius: 0 }} className="OVWKvl3A">
      <Card bodyStyle={{ paddingBottom: 0, paddingLeft: 0, paddingRight: 0 }} style={{ border: 0 }}>
        <Card.Meta
          style={{ paddingTop: 0, paddingBottom: 16 }}
          title={
            <div style={{ display: 'flex' }}>
              <div style={{ display: 'flex', alignItems: 'center', fontSize: 14, paddingLeft: 8 }}>
                <span>供应商采购单：</span>
                <span style={{ marginLeft: 8 }}>{supplierOrder.orderNo}</span>
              </div>
              {/* <div style={{ display: 'flex', alignItems: 'center', fontSize: 14, paddingLeft: 8 }}>
                <span>供应商：</span>
                <span style={{ marginLeft: 8 }}>{supplierOrder.supplierName ? supplierOrder.supplierName : supplierOrder.supplierSellerNo}</span>
              </div> */}
              {
                supplierOrder.status === 'PENDING_PAY' && supplierOrder.payUrl &&
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button type='primary' onClick={() => gotoPay(supplierOrder)} style={{ marginLeft: 8 }}>支付订单</Button>
                  {remainingTime > 0 && (
                    <div style={{ marginLeft: 8, fontSize: 14 }}>
                      剩余时间：{formatTime(remainingTime)}
                    </div>
                  )}
                </div>
              }
              {
                supplierOrder.status === 'CANCELLED' &&
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button type='primary' onClick={() => reCreatePurchaseSupplierOrder(supplierOrder)} style={{ marginLeft: 8 }}>重新创建</Button>
                </div>
              }
            </div>
          }
          description={
            <Descriptions size="small" column={3} style={{ paddingLeft: 8 }}>
              {/* <Descriptions.Item>1688订单：<a href="/cn/admin/orders/po/P3048557127001">P3048557127001</a></Descriptions.Item> */}
              <Descriptions.Item>供应商：
                <a href={`https://s.1688.com/company/company_search.htm?keywords=${encodeURIComponent(supplierOrder.supplier.companyName)}`} target='_blank'>
                  <span style={{ marginLeft: 8 }}>{supplierOrder.supplier.companyName ? supplierOrder.supplier.companyName : supplierOrder.supplier.supplierNo}</span>
                </a>
              </Descriptions.Item>
              <Descriptions.Item>订单状态：<Tag><PurchaseSupplierOrderStatus status={supplierOrder.status} /></Tag></Descriptions.Item>
              <Descriptions.Item>支付状态：<Tag><PurchaseSupplierOrderPayStatus status={supplierOrder.payStatus} /></Tag></Descriptions.Item>
            </Descriptions>
          }
        />
        <Row gutter={0} style={{ borderTop: '1px solid #dcdcdc', }}>
          <Col span={20}>
            <Table
              columns={columns}
              dataSource={dataSource}
              pagination={false}
              size="small"
              style={{ borderRadius: 0 }}
            />
            {
              supplierOrder.orderProducts.length > 3 && (
                <div style={{ textAlign: 'center', }}>
                  <Button type="link" onClick={() => setShowAll(!showAll)}>
                    {showAll ? <UpOutlined /> : <DownOutlined />}
                    {showAll ? '隐藏' : `还有${supplierOrder.orderProducts.length - 3}个商品，查看所有`}
                  </Button>
                </div>
              )
            }
          </Col>
          <Col span={4}>
            <Descriptions size="small" column={1} style={{ borderLeft: '1px solid #dcdcdc', height: '100%', paddingTop: 8, paddingLeft: 8 }}>
              {/* <Descriptions.Item label="商品件数">
                <Text strong><span>{supplierOrder.orderProducts.length}</span></Text>
              </Descriptions.Item> */}
              <Descriptions.Item label="国内运费">
                <Text strong><span>¥{supplierOrder.shippingFee}</span></Text>
              </Descriptions.Item>
              <Descriptions.Item label="总价">
                <Text strong>
                  <span>¥{supplierOrder.orderTotal}</span>
                </Text>
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      </Card>
    </Card>
  );
};

const IntlLogisticsWarehouseReceiptStatus = {
  PENDING: '待处理',
  ORDERED: '已下单',
  IN_WAREHOUSE: '已入库',
  IN_TRANSIT: '转运中',
  DESTPORT_ARRIVED: '到达目的港',
  CLEARANCE: '清关中',
  LOCAL_SHIPPING: '本地配送',
  DELIVERED: '已送达',
  CANCELLED: '已取消',
  EXCEPTION: '异常状态',
};

const LogisticsQueryPopover = ({ record = {} }) => {
  const { t } = useTranslation();
  const [popoverVisible, setPopoverVisible] = useState(false); // State for Popover visibility
  const [selectedTab, setSelectedTab] = useState('logistics'); // State for selected tab

  const handlePopoverVisibleChange = (visible) => {
    setPopoverVisible(visible);
  };

  const handleTabChange = (key) => {
    setSelectedTab(key);
  };

  return (
    <Popover
      content={
        <Tabs defaultActiveKey={selectedTab} onChange={handleTabChange}>
          <TabPane tab={t("shopOrders.orderDetail.tab.logistics")} key="logistics">
            <LogisticsTracking id={record.id} />
          </TabPane>
          <TabPane tab={t("shopOrders.orderDetail.tab.intl_logistics")} key="intl_logistics">
            <IntlLogisticsTracking id={record.id} />
          </TabPane>
        </Tabs>
      }
      title={t("shopOrders.orderDetail.logisticsQuery")}
      visible={popoverVisible}
      onVisibleChange={handlePopoverVisibleChange}
      trigger={"click"}
    >
      <Text ellipsis={{ rows: 3, expandable: false }} className="ant-typography-secondary"><a>{t("shopOrders.orderDetail.logisticsQuery")}</a></Text>
    </Popover>
  )
}


const WarehouseReceiptTable = ({ warehouseReceipts = [] }) => {

  const warehouseReceiptColumns = [
    {
      title: '仓号',
      dataIndex: 'receiptNo',
      key: 'receiptNo',
      render: (text) => <a>{text}</a>,
    },
    {
      title: '仓单状态',
      dataIndex: 'status',
      key: 'status',
      render: (text) => {
        // Map the status to its corresponding tag color and name from the enum
        const statusName = IntlLogisticsWarehouseReceiptStatus[text] || text;
        const statusColor =
          text === 'DELIVERED' ? 'green' :
            text === 'CANCELLED' ? 'red' :
              text === 'IN_TRANSIT' ? 'orange' :
                'default'; // Default color if no match

        return <Tag color={statusColor}>{statusName}</Tag>;
      },
    },
    {
      title: '商品总数量',
      dataIndex: 'totalItemsQuantity',
      key: 'totalItemsQuantity',
      render: (text) => <span>{text}</span>,
    },
    {
      title: '总体积(m³)',
      dataIndex: 'totalItemsBoxVolume',
      key: 'totalItemsBoxVolume',
      render: (text, record) => <span>{record.totalItemsBoxVolume/1000}</span>,
    },
    {
      title: '毛重(Kg)',
      dataIndex: 'totalItemsWeight',
      key: 'totalItemsWeight',
      render: (text) => <span>{text}</span>,
    },
    {
      title: '总货值',
      dataIndex: 'totalItemsPrice',
      key: 'totalItemsPrice',
      render: (text) => <span>¥{text}</span>,
    },
    {
      title: '估算运费(RMB)',
      dataIndex: 'totalItemsFreightCost',
      key: 'totalItemsFreightCost',
      render: (text, record) => <span>¥{record.totalItemsFreightCost}</span>,
    },
    {
      title: '实际运费(RMB)',
      dataIndex: 'actualFreightCost',
      key: 'actualFreightCost',
      render: (text, record) => <span>¥{record.actualFreightCost}</span>,
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <Space>
          {
            record.status !== 'PENDING' && (
              <IntlLogisticsTrackingPopup id={record.id} receiptNo={record.receiptNo} />
            )
          }
        </Space>
      ),
    },
  ];

  return (
    <Table
      columns={warehouseReceiptColumns}
      dataSource={warehouseReceipts}
      rowKey="receiptNo"
      style={{ marginTop: '20px' }}
      pagination={false}
      expandable={{
        expandedRowRender: (record) => (
          <Table
            size="small"
            columns={[
              { title: '商品名', dataIndex: 'name', key: 'name', width: '30%' },
              {
                title: 'SKU',
                dataIndex: 'skuInfo',
                key: 'skuInfo',
                render: (_, record) => (<span>{record.skuInfo}</span>)
              },
              { title: '箱数', dataIndex: 'boxCount', key: 'boxCount' },
              { title: '每箱数量', dataIndex: 'boxPackQty', key: 'boxPackQty' },
              { title: '商品数量', dataIndex: 'quantity', key: 'quantity' },
              {
                title: '总体积(m³)',
                dataIndex: 'totalBoxVolume',
                key: 'totalBoxVolume',
                render: (_, record) => (
                  <span>{`${record.totalBoxVolume/1000}`}</span>
                )
              },
              {
                title: '毛重(kg)',
                dataIndex: 'totalWeight',
                key: 'totalWeight',
              },
              {
                title: '货值',
                dataIndex: 'totalPrice',
                key: 'totalPrice',
                render: (text, record) => (<span>¥{record.totalPrice}</span>)
              },
            ]}
            dataSource={record.items}
            rowKey="name"
            pagination={false}
          />
        ),
        rowExpandable: (record) => record.items && record.items.length > 0,
      }}
    />
  )
}

const PurchaseOrderCard = ({ purchaseOrder, onConfirm, onReCreateSupplierOrder }) => {
  const { t } = useTranslation();
  const [showSupplierOrders, setShowSupplierOrders] = useState(false);
  const [intlLogisticsProviders, setIntlLogisticsProviders] = useState([]);
  const [selectedProviderId, setSelectedProviderId] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    logisticsApi.getAllIntlLogisticsProviders()
      .then((providers = []) => {
        setIntlLogisticsProviders(providers);
        if (providers.length > 0) {
          setSelectedProviderId(providers[0].id);
        }
      })
  }, [])

  const toggleSupplierOrders = () => {
    setShowSupplierOrders(prevState => !prevState);
  };

  const gotoPay = (purchaseOrder) => {
    const payUrls = purchaseOrder.purchaseSupplierOrders.map(order => order.payUrl);

    const orderIds = payUrls.flatMap(url => {
      const match = url.match(/orderId=([\d,]+)/);
      if (match) {
        return match[1].split(',');
      }
      return [];
    });

    return window.open(`https://trade.1688.com/order/cashier.htm?orderId=${orderIds.join(',')}`);
  };

  const handleConfirm = (e, purchaseOrder) => {
    preventAndStop(e);
    onConfirm(purchaseOrder, selectedProviderId)
  }

  const saleOrderIds = purchaseOrder.purchaseSupplierOrders
    .map(purchaseSupplierOrder => purchaseSupplierOrder.saleOrderIds) // 提取每个 saleOrderIds 数组
    .reduce((sum, item) => sum.concat(item), []); // 合并数组

  // 如果需要去重
  const uniqueSaleOrderIds = [...new Set(saleOrderIds)];

  const popoverContent = (
    <div>
      {uniqueSaleOrderIds.map(id => (
        <a key={id}>{id}</a>
      ))}
    </div>
  );

  // const getCustomerAddress = () => {
  //   return `${saleOrderFulfillment.deliveryFirstName},${saleOrderFulfillment.deliveryStreetAddress},${saleOrderFulfillment.deliveryCity},${saleOrderFulfillment.deliveryState}`
  // }

  // const customerInfoContent = (
  //   <div>
  //     <div><strong>姓名：</strong>{saleOrderFulfillment.deliveryFirstName}</div>
  //     <div><strong>地址：</strong>{getCustomerAddress()}</div>
  //   </div>
  // );

  const orderProductsInWarehouseReceiptSize = purchaseOrder?.warehouseReceipts?.map(receipt => receipt.items.length).reduce((sum, size) => sum += size, 0);
  const orderProductsSize = purchaseOrder?.purchaseSupplierOrders?.map(pso => pso.orderProducts.length).reduce((sum, size) => sum += size, 0);

  return (
    <Card bodyStyle={{ padding: 0, background: 'transparent' }} style={{ marginBottom: 16, borderRadius: 0 }}>
      <table className='iIzGMbY1' style={{ marginBottom: 8, paddingTop: 8, cursor: 'pointer' }} onClick={toggleSupplierOrders}>
        <tbody>
          <tr>
            <td>
              <Descriptions style={{ paddingLeft: 16, paddingTop: 8 }}>
                <Descriptions.Item>
                  <FileTextOutlined /> <span className="ml-8">采购订单：</span>
                  <a target="_blank" >{purchaseOrder.orderNo}</a>
                </Descriptions.Item>
              </Descriptions>
            </td>
            <td>
              <Descriptions style={{ paddingTop: 8 }}>
                <Descriptions.Item>
                  <FileTextOutlined /> <span className="ml-8">销售订单：</span>
                  {
                    uniqueSaleOrderIds.length > 1 && (
                      <Popover content={popoverContent} title="销售订单">
                        <Tag>订单列表</Tag>
                      </Popover>
                    )
                  }
                  {
                    uniqueSaleOrderIds.length == 1 && (
                      <a target="_blank" >{uniqueSaleOrderIds[0]}</a>
                    )
                  }
                </Descriptions.Item>
              </Descriptions>
            </td>
            <td>
              <Descriptions style={{ paddingTop: 8 }}>
                <Descriptions.Item>
                  <ClockCircleOutlined /> <span className="ml-8">创建时间：</span>
                  <span>{formatDateTime(purchaseOrder.createdTime)}</span>
                </Descriptions.Item>
              </Descriptions>
            </td>
          </tr>
          <tr>
            <td>
              <Descriptions style={{ paddingLeft: 16, paddingTop: 8 }}>
                <Descriptions.Item>
                  <UserOutlined /> <span className="ml-8">客户信息：</span>
                  <span>Nicolas, Gotland, 杭州市滨江区</span>
                </Descriptions.Item>
              </Descriptions>
            </td>
            <td>
              <Descriptions style={{ paddingTop: 8 }}>
                <Descriptions.Item>
                  <UserOutlined /> <span className="ml-8">采购状态：</span>
                  <span><PurchaseOrderStatus status={purchaseOrder.status} /></span>
                </Descriptions.Item>
              </Descriptions>
            </td>
            <td>
              <div>
                {purchaseOrder.status === 'NEW' && (
                  <div style={{ display: 'flex' }} >
                    <AccountBookOutlined />
                    <div style={{ display: 'flex', alignItems: 'center', marginRight: 12 }}>
                      <span className='ml-8'>国际物流：</span>
                      <div onClick={preventAndStop}>
                        <Select style={{ width: 120 }} value={selectedProviderId} onChange={value => setSelectedProviderId(value)}>
                          {
                            intlLogisticsProviders.map(provider => (<Option value={provider.id}>{provider.providerName}</Option>))
                          }
                        </Select>
                      </div>
                    </div>
                    <Button type='primary' onClick={e => handleConfirm(e, purchaseOrder)}>确认采购</Button>
                  </div>
                )}
                {purchaseOrder.status !== 'NEW' && (
                  <Descriptions style={{ paddingTop: 8 }}>
                    <Descriptions.Item>
                      <UserOutlined /> <span className="ml-8">已支付订单：</span>
                      <span>{purchaseOrder.purchaseSupplierOrders.filter(purchaseSupplierOrder => purchaseSupplierOrder.payStatus === 'PAID').length}/{purchaseOrder.purchaseSupplierOrders.length}</span>
                    </Descriptions.Item>
                  </Descriptions>
                )}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div style={{ position: 'absolute', top: 0, right: 0 }}>
        <Button type="link" onClick={toggleSupplierOrders}>
          {showSupplierOrders ? <UpOutlined /> : <DownOutlined />}订单详情
        </Button>
      </div>
      {
        purchaseOrder.status === 'COMPLETED' && (
          <div style={{ position: 'absolute', top: 4, left: 4}}>
            <CheckCircleOutlined style={{ color: '#52c41a', fontSize: 18  }}/>
          </div>
        )
      }
      {
        showSupplierOrders && (
          <Tabs defaultActiveKey="1" type="card">
            <TabPane tab="采购订单详情" key="1">
              {
                <>
                  {(purchaseOrder.purchaseSupplierOrders || []).map(supplierOrder => (
                    <SupplierOrderCard key={supplierOrder.id} supplierOrder={supplierOrder} onReCreateSupplierOrder={onReCreateSupplierOrder} />
                  ))}
                  <div style={{ paddingRight: 24 }}>
                    <Descriptions size="small" column={1} style={{ height: '100%', paddingTop: 8, paddingLeft: 8, }}>
                      {/* <Descriptions.Item label="总商品件数" style={{ float: 'right' }}>
                        <Text strong style={{ color: 'blue' }}>
                          <span>
                            {purchaseOrder.purchaseSupplierOrders.map(purchaseSupplierOrder => purchaseSupplierOrder.orderProducts.length).reduce((sum, len) => sum += len, 0)}
                          </span>
                        </Text>
                      </Descriptions.Item> */}
                      {/* <Descriptions.Item label="国内总运费" style={{ float: 'right' }}>
                        <Text strong style={{ color: 'blue' }}><span>¥{purchaseOrder.shippingFee}</span></Text>
                      </Descriptions.Item> */}
                      <Descriptions.Item label="商品总价" style={{ float: 'right' }}>
                        <Text strong style={{ color: 'blue' }}>
                          <span>¥{purchaseOrder.orderSubTotal}</span>
                        </Text>
                      </Descriptions.Item>
                      <Descriptions.Item label="国内运费" style={{ float: 'right' }}>
                        <Text strong style={{ color: 'blue' }}><span>¥{purchaseOrder.shippingFee}</span></Text>
                      </Descriptions.Item>
                      <Descriptions.Item label="国际运费" style={{ float: 'right' }}>
                        <Text strong style={{ color: 'blue' }}><span>¥{purchaseOrder.actualFreightCost}</span></Text>
                      </Descriptions.Item>
                      <Descriptions.Item label="总价" style={{ float: 'right' }}>
                        <Text strong style={{ color: 'blue' }}>
                          <span>¥{purchaseOrder.orderTotal}</span>
                        </Text>
                      </Descriptions.Item>
                    </Descriptions>
                  </div>
                </>

              }
            </TabPane>
            {
              purchaseOrder.status !== 'NEW' && (
                <TabPane tab="国际物流仓单" key="2">
                  <div style={{ padding: 16 }}>
                    <Descriptions size="small" >
                      <Descriptions.Item label="物流服务商">
                        <Text strong>{intlLogisticsProviders.find(provider => provider.id === selectedProviderId)?.providerName || '无'}</Text>
                      </Descriptions.Item>
                      <Descriptions.Item label="已建仓单商品">
                        <Text strong>{orderProductsInWarehouseReceiptSize}/{orderProductsSize}</Text>
                      </Descriptions.Item>
                      <Descriptions.Item label="仓单数" >
                        <Text strong>{purchaseOrder?.warehouseReceipts?.length}</Text>
                      </Descriptions.Item>
                      <Descriptions.Item label="完成进度">
                        <Text strong>{orderProductsInWarehouseReceiptSize > 0 && orderProductsInWarehouseReceiptSize === orderProductsSize ? '已完成' : "正在进行"}</Text>
                      </Descriptions.Item>
                      <Descriptions.Item label="运费计算" >
                        <Text strong>¥{purchaseOrder?.freightCost}</Text>
                      </Descriptions.Item>
                      <Descriptions.Item label="实际运费" >
                        <Text strong>¥{purchaseOrder?.actualFreightCost}</Text>
                      </Descriptions.Item>
                    </Descriptions>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button type="primary" onClick={() => window.open(`/purchase/purchase_orders/${purchaseOrder.id}/assign_warehouse_receipt`, '_blank')}>分仓管理</Button>
                    </div>
                  </div>
                  <div style={{ paddingBottom: 24 }}>
                    <WarehouseReceiptTable warehouseReceipts={purchaseOrder.warehouseReceipts} />
                  </div>
                </TabPane>
              )
            }
          </Tabs>
        )
      }
    </Card>
  );
};

export default props => {
  const [data, setData] = useState([]);
  const [searchParams, setSearchParams] = useState({});
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 100,
  });

  React.useEffect(() => {
    fetchPurchaseOrders({ searchParams, pagination });
  }, [pagination.current, pagination.pageSize, searchParams])

  const fetchPurchaseOrders = async ({ searchParams, pagination }) => {
    const pageData = await purchaseOrderApi.getPurchaseOrders({
      ...searchParams,
      page: pagination.current - 1,
      size: pagination.pageSize,
    })

    setData(pageData.purchaseOrders || []);
    setPagination({
      ...pagination,
      current: pageData.number + 1,
      total: pageData.recordsTotal,
    })
  }

  const handleConfirmPurchaseOrder = async (orderDetail, providerId) => {
    const hide = message.loading("正在确认采购单", 0);
    const resp = await purchaseOrderApi.confirmPurchaseOrder(orderDetail.id, {
      providerId,
    });
    console.log(resp);
    hide();
    message.success("采购单已确认，等待采购处理");

    fetchPurchaseOrders({
      pagination
    })
  }

  const handlePageChange = (current, pageSize) => {
    setPagination({
      ...pagination,
      current,
      pageSize,
    })
  };

  const handleReCreatePurchaseSupplierOrder = async supplierOrder => {
    const hide = message.loading('正在创建供应商采购单', 0);
    const resp = await purchaseOrderApi.reCreatePurchaseSupplierOrder(supplierOrder.id);
    console.log(resp);

    hide();
    message.success('供应商采购单已创建');

    fetchPurchaseOrders({
      pagination
    })
  }


  return (
    <>
      <div className="page_head">
        <div id="page_head_content" style={{ height: '72px' }}>
          <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
            <Col span={12}>
              <div className="flex-center-vertical">
                <h3 className="ant-typography ant-typography-ellipsis ant-typography-single-line ant-typography-ellipsis-single-line text-24" style={{ margin: 0, lineHeight: '72px', minHeight: '72px', padding: '0px 8px', flexGrow: 1 }}>
                  采购单
                </h3>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className='inner-content relative px-[16px]' style={{ paddingTop: '82px', paddingBottom: '32px' }}>
        <SearchOrders onSearch={setSearchParams} onReset={() => setSearchParams({})} />
        <div className="order-detail-list" style={{ margin: 20 }}>
          {
            data.map(purchaseOrder => <PurchaseOrderCard key={purchaseOrder.id} purchaseOrder={purchaseOrder} onConfirm={handleConfirmPurchaseOrder} onReCreateSupplierOrder={handleReCreatePurchaseSupplierOrder} />)
          }

          {/* <PurchaseOrderCard /> */}
        </div>
        <div className="pagination" style={{ display: 'flex', justifyContent: 'flex-end', margin: 20 }} >
          <Pagination
            current={pagination.current}
            pageSize={pagination.pageSize}
            total={pagination.total}
            onChange={handlePageChange}
            showSizeChanger
            pageSizeOptions={['5', '10', '20']}
          />
        </div>
      </div>
    </>
  )
}