import React from "react";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";
const { Text } = Typography;

const SaleOrderStatus = ({ status }) => {
  const { t } = useTranslation();
  const getStatusText = (status) => {
    switch (status) {
      case 'PENDING_PAYMENT':
        return t('status.saleOrderStatus.PENDING_PAYMENT');
      case 'PENDING_CONFIRM':
        return t('status.saleOrderStatus.PENDING_CONFIRM');
      case 'CONFIRMED':
        return t('status.saleOrderStatus.CONFIRMED');
      case 'PROCESSING':
        return t('status.saleOrderStatus.PROCESSING');
      case 'COMPLETED':
        return t('status.saleOrderStatus.COMPLETED');
      case 'CANCELLED':
        return t('status.saleOrderStatus.CANCELLED');
      case 'ON_HOLD':
        return t('status.saleOrderStatus.ON_HOLD');
      case 'REFUNDED':
        return t('status.saleOrderStatus.REFUNDED');
      case 'RETURNED':
        return t('status.saleOrderStatus.RETURNED');
      case 'FAILED':
        return t('status.saleOrderStatus.FAILED');
      default:
        return t('status.saleOrderStatus.UNKNOWN');
    }
  };

  return <Text>{getStatusText(status)}</Text>;
};


export default SaleOrderStatus;