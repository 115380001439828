import { create } from "../util/request";
import i18n from 'i18next';
import qs from 'qs';
import { saveAs } from "file-saver";

const api = create({ baseURL: '' })

class LogisticsApi {

  async getWarehouseReceiptCategories() {
    return await api.get(`/api/v1/intl_logistics/warehouse_receipts/categories`)
  }

  async updateLogisticsWarehouseReceiptCategory(id, params) {
    return await api.post(`/api/v1/logistics/${id}/warehouse_receipt_categories`, params);
  }

  async updateLogisticsOrderGoodsSpecification(id, params) {
    return await api.post(`/api/v1/logistics_order_goods/${id}/specification`, params);
  }

  async autoAssignWarehouseReceipts(params) {
    return await api.post(`/api/v1/intl_logistics/warehouse_receipts/auto_assign`, params);
  }

  async submitWarehouseReceipts(params) {
    return await api.post(`/api/v1/intl_logistics/warehouse_receipts/submit`, params);
  }

  async deleteWarehouseReceipts(params) {
    return await api.post(`/api/v1/intl_logistics/warehouse_receipts/batch_del`, params);
  }

  async getLogisticsTracesByLogisticsId(id) {
    return await api.get(`/api/v1/logistics/${id}/traces`);
  }

  async createWarehouseReceipt(params) {
    return await api.post(`/api/v1/intl_logistics/warehouse_receipts`, params);
  }

  async getAllIntlLogisticsProviders() {
    return await api.get(`/api/v1/intl_logistics/providers`);
  }

  async downloadWarehouseReceiptItemPdf(id) {
    try {
      const response = await fetch(`/api/v1/intl_logistics/warehouse_receipt_items/${id}/pdf`, {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to download PDF");
      }

      const disposition = response.headers.get('Content-Disposition');
      let filename = 'receipt.pdf';  // 默认文件名

      if (disposition && disposition.includes('attachment')) {
        // 提取 filename* 中的编码文件名
        const matches = /filename\*=UTF-8''([^;]+)/.exec(disposition);
        if (matches != null && matches[1]) {
          // URL 解码文件名
          filename = decodeURIComponent(matches[1]);
        }
      }


      const blob = await response.blob();
      saveAs(blob, filename); // 使用 file-saver 下载 PDF
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  }

  async getWarehouseReceiptTracks(id) {
    return await api.get(`/api/v1/intl_logistics/warehouse_receipts/${id}/tracks`);
  }
}

const logisticsApi = new LogisticsApi();
export default logisticsApi;