import React from "react";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";
const { Text } = Typography;

const PurchaseSupplierOrderProductStatus = ({ status }) => {
  const { t } = useTranslation();
  const getStatusText = (status) => {
    switch (status) {
      case 'PENDING':
        return t('status.purchaseSupplierOrderProductStatus.PENDING');
      case 'SHIPPED':
        return t('status.purchaseSupplierOrderProductStatus.SHIPPED');
      case 'RECEIVED':
        return t('status.purchaseSupplierOrderProductStatus.RECEIVED');
      case 'IN_WAREHOUSE':
        return t('status.purchaseSupplierOrderProductStatus.IN_WAREHOUSE');
      case 'IN_TRANSIT':
        return t('status.purchaseSupplierOrderProductStatus.IN_TRANSIT');
      case 'DELIVERED':
        return t('status.purchaseSupplierOrderProductStatus.DELIVERED');
      case 'CANCELLED':
        return t('status.purchaseSupplierOrderProductStatus.CANCELLED');
      case 'RETURNED':
        return t('status.purchaseSupplierOrderProductStatus.RETURNED');
      default:
        return t('status.purchaseSupplierOrderProductStatus.UNKNOWN');
    }
  };

  return <Text>{getStatusText(status)}</Text>;
};


export default PurchaseSupplierOrderProductStatus;